export const SNAKE_CASE_REGEX = /(_\w)/g;

export const CAPITAL_LETTERS_REGEX = /[A-Z]/g;

export const BLANK_REGEX = /^\s*$/;

export const NUMBERS_REGEX = /\d+/g;

export const LETTERS_REGEX = /\D+/g;

export const SPACES_REGEX = /\s/g;

export const DOT_ANOTATION = /^[0-9]*\.?[0-9]*$/;

export const FROM_0_TO_99_REGEX = /^[1-9][0-9]?$/;

export const FROM_0_TO_99_W2_DECIMALS_REGEX = /^[0-9]{1,2}([.]{1}[0-9]{0,2}){0,1}$/gm;

export const FROM_0_TO_99_W3_DECIMALS_REGEX = /^[0-9]{1,2}([.]{1}[0-9]{0,3}){0,1}$/gm;

export const FROM_0_TO_999_W1_DECIMAL_REGEX = /^[0-9]{1,3}([.]{1}[0-9]{0,1}){0,1}$/gm;

export const FROM_0_TO_100_W1_DECIMAL_REGEX =
  /^(100(([.]{1}[0]{0,1}){0,1})?|[1-9]?\d(([.]{1}[0-9]{0,1}){0,1})?)$/;

export const FROM_0_TO_100_W2_DECIMAL_REGEX =
  /^(100(([.]{1}[0]{0,2}){0,1})?|[1-9]?\d(([.]{1}[0-9]{0,2}){0,1})?)$/;

export const FROM_1_TO_8_REGEX = /(^(?:(?!\S).)*)[1-8](?!\S)/;

export const FROM_1_TO_9_W3_DECIMALS_REGEX = /^[1-9]?(([.]{1}[0-9]{0,3}){0,1})?$/;

export const FROM_0_TO_9_W1_DECIMALS_REGEX = /^[0-9]?(([.]{1}[0-9]{0,1}){0,1})?$/;

export const Y_N_REGEX = /(^(?:(?!\S).)*)[YNyn](?!\S)/;

export const FIVE_NUMBERS_REGEX = /^[0-9]{1,5}$/;

export const FIVE_NUMBERS_W1_DECIMAL_REGEX = /^[0-9]{1,5}([.]{1}[0-9]{0,1}){0,1}$/;

export const FOUR_NUMBERS_REGEX = /^[0-9]{1,4}$/;

export const FOUR_NUMBERS_W1_DECIMAL_REGEX = /^[0-9]{1,4}([.]{1}[0-9]{0,1}){0,1}$/;

export const URL_REGEX =
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;

export const FROM_0_TO_9999_3_DECIMAL_REGEX = /^[0-9]{1,4}([.]{1}[0-9]{0,3}){0,1}$/gm;

export const NEGATIVES_W1_DECIMAL_REGEX = /^-?[0-9]?\d*([.]{1}[0-9]{0,1}){0,1}$/g;
