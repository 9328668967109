export const PRODUCTS_TARGETS = {
  CREATE_PRODUCT: 'createProduct',
  PRODUCTS: 'products',
  CURRENT_PRODUCT: 'currentProduct',
  EDIT_PRODUCT: 'editProduct',
  GENERATE_PRODUCT_URLS: 'generateProductURLs',
  PRODUCT_WITH_SEQUENTIAL_NUMBER: 'productWithSequentialNumber'
};

export const PRODUCTS_DEFAULT_STATE = {
  [PRODUCTS_TARGETS.CREATE_PRODUCT]: null,
  [PRODUCTS_TARGETS.PRODUCTS]: [],
  [PRODUCTS_TARGETS.CURRENT_PRODUCT]: {},
  [PRODUCTS_TARGETS.EDIT_PRODUCT]: null,
  [PRODUCTS_TARGETS.GENERATE_PRODUCT_URLS]: null,
  [PRODUCTS_TARGETS.PRODUCT_WITH_SEQUENTIAL_NUMBER]: {}
};
