import { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

use(LanguageDetector).init({
  order: ['querystring', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  caches: [],
  lookupCookie: null,
  cookieMinutes: 0,
  fallbackLng: 'en',
  supportedLngs: ['en', 'fr', 'it', 'de', 'zh', 'zt']
});

requireAll(require.context('..', true, /i18n\.js$/));
