import i18next from 'i18next';

i18next.addResources('en', 'AdelphiMashingAndFermentationTotalsModal', {
  totalsTitle: 'Totals',
  tones: 'Tonnes',
  anchor: 'Yeast - Anchor',
  fermentis: 'Yeast - Fermentis',
  wortVolume: 'Wort Volume',
  originalGravity: 'OG',
  finalGravity: 'FG',
  da: 'DA',
  mte: 'MTE',
  psy: 'PSY',
  alcoholAtt: 'Alcohol Att'
});
