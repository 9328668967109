import cn from 'classnames';
import { bool, func, string } from 'prop-types';

import styles from './styles.module.scss';

function Checkbox({
  containerClassName,
  placeholder: label,
  name,
  disabled,
  checked,
  labelClassName,
  checkboxClassName,
  onChange,
  defaultChecked,
  register,
  ...checkboxProps
}) {
  const registerParams = register(name, checkboxProps);
  return (
    <div className={cn(styles.container, containerClassName)}>
      <label className={cn(styles.checkboxLabel, checkboxClassName)}>
        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          defaultChecked={defaultChecked}
          {...checkboxProps}
          {...registerParams}
        />
        <span className={styles.checkboxCustom} />
      </label>
      <span className={cn(styles.label, labelClassName)}>{label}</span>
    </div>
  );
}

Checkbox.propTypes = {
  checkboxClassName: string,
  checked: bool,
  containerClassName: string,
  defaultChecked: bool,
  disabled: bool,
  labelClassName: string,
  name: string,
  placeholder: string,
  register: func,
  onChange: func
};

export default Checkbox;
