import loading from './assets/loading.json';
import whiteLoading from './assets/white-loading.json';

export const SPINNER_KEYS = {
  LOADING: 'loading',
  WHITE_LOADING: 'whiteLoading'
};

export const SPINNER_TYPES = {
  [SPINNER_KEYS.LOADING]: loading,
  [SPINNER_KEYS.WHITE_LOADING]: whiteLoading
};

export const getOptions = ({ type, loop = true, autoplay = true, rendererSettings }) => ({
  loop,
  autoplay,
  animationData: SPINNER_TYPES[type] || SPINNER_TYPES.loading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    ...rendererSettings
  }
});
