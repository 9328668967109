import { bool, func, shape, string } from 'prop-types';
import { Route } from 'react-router-dom';

import ErrorBoundary from '#components/general/ErrorBoundary';
import ErrorFallback from '#components/general/ErrorFallback';
import Helmet from '#components/general/Helmet';
import { ROUTES } from '#constants/routes';
import { isEmpty } from '#utils/array';

import { getRedirect } from './modals';

function AuthenticatedRouteLayout({
  title,
  description,
  path,
  component: Component,
  componentProps,
  handleRedirect,
  redirectRoute,
  ...props
}) {
  const redirect = !isEmpty(redirectRoute);
  return redirect ? (
    getRedirect(redirectRoute)
  ) : (
    <>
      <Helmet title={title} description={description} />
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={handleRedirect(ROUTES.ERROR.path)}>
        <Route path={path} render={() => <Component {...componentProps} />} {...props} />
      </ErrorBoundary>
    </>
  );
}

AuthenticatedRouteLayout.propTypes = {
  path: string.isRequired,
  component: shape,
  componentProps: shape,
  description: string,
  handleRedirect: func,
  publicRoute: bool,
  redirectPath: string,
  redirectRoute: string,
  title: string
};

export default AuthenticatedRouteLayout;
