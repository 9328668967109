import i18next from 'i18next';

i18next.addResources('en', 'GreenDistillery', {
  ardnamurchan: 'Ardnamurchan',
  green: 'The Green Distillery',
  firstDescription:
    'Whisky is a natural product and we’ve ensured this is reflected in the way we operate. Uniquely in the whisky industry, all the power and heat requirements for the Ardnamurchan Distillery come from local renewables – the river that provides the distillery’s cooling water has a hydro-electricity generator and the biomass boiler is fuelled by woodchip from local forestry – the overall footprint of the distillery is a light one.',
  secondDescription:
    'In addition, the by-products of the whisky-making process are recycled on the peninsula – the still energy-rich draff (what is left in the mash tun) is combined with the pot ale to produce animal feed for the use on the peninsula.'
});

i18next.addResources('fr', 'GreenDistillery', {
  ardnamurchan: 'Ardnamurchan',
  green: 'la distillerie verte',
  firstDescription:
    "Le whisky est un produit naturel, un fait que nous avons cherché à souligner dans notre façon de travailler. Unique dans l’industrie du whisky, tous les besoins en électricité et en chaleur de la distillerie Ardnamurchan proviennent d’énergies locales et renouvelables. La rivière qui fournit l'eau de refroidissement de la distillerie dispose d'un générateur hydroélectrique. Notre chaudière à biomasse est alimentée par des copeaux de bois provenant de la foresterie locale : l'empreinte globale de la distillerie est légère.",
  secondDescription:
    'De plus, les sous-produits du processus de fabrication du whisky sont recyclés sur la péninsule - la drèche (ce qui reste dans la cuve de brassage) encore riche en énergie est combinée avec le ‘pot-ale’ - la bière d’alambic, (ce qui reste après la première distillation), produisant ainsi de quoi nourrir le bétail et les chevreuils d’Ardnamurchan. '
});

i18next.addResources('it', 'GreenDistillery', {
  ardnamurchan: 'Ardnamurchan',
  green: 'la Distilleria Green',
  firstDescription:
    "Il whisky è un prodotto naturale e ci siamo assicurati che questo si riflettesse nel modo in cui operiamo. Unico nel mondo del whisky, tutto il fabbisogno energetico e termico della distilleria Ardnamurchan proviene da fonti rinnovabili locali: il fiume che fornisce l'acqua di raffreddamento della distilleria ha un generatore di energia idroelettrica e la caldaia a biomassa è alimentata da cippato proveniente dalla silvicoltura locale. L’impatto ambientale della distilleria è minimo.",
  secondDescription:
    'Inoltre, nella penisola vengono riciclati i sottoprodotti del processo di produzione del whisky: il residuo del mosto ancora ricco di elementi nutritivi (ciò che rimane nel mash tun) viene mescolato con la “pot ale” (il liquido rimasto dopo la prima distillazione) per produrre mangimi per gli animali della penisola.'
});

i18next.addResources('de', 'GreenDistillery', {
  ardnamurchan: 'Ardnamurchan',
  green: 'die Grüne Destillerie',
  firstDescription:
    'Whisky ist ein Naturprodukt, und wir haben dafür gesorgt, dass sich dies in unserer Arbeitsweise widerspiegelt. Einzigartig in der Whiskyindustrie, stammt der gesamte Strom- und Wärmebedarf der Ardnamurchan Distillery aus lokalen erneuerbaren Energien – der Fluss, der das Kühlwasser der Destillerie liefert, hat einen Wasserkraftgenerator und der Biomassekessel wird mit Holzhackschnitzeln aus lokaler Forstwirtschaft betrieben – der Gesamtfussabdruck der Brennerei ist niedrig.',
  secondDescription:
    'Zusätzlich werden die Nebenprodukte der Whiskyherstellung auf der Halbinsel wiederverwertet – der noch energiereiche Treber (was im Maischbottich übrig bleibt) wird mit dem Pot Ale kombiniert, um Tierfutter für die Verwendung auf der Halbinsel herzustellen .'
});

i18next.addResources('zh', 'GreenDistillery', {
  ardnamurchan: '艾德麦康',
  green: '绿色永续蒸馏厂',
  firstDescription:
    '威士忌是天然的产品与环境息息相关，而我们确保所有的营运方式完美体现这个精神。艾德麦康蒸馏厂所使用的电力与热能需求均来自当地的可再生能源，我们致力于创造最小化的整体环境足迹，这在威士忌行业中难能可贵且独树一帜。',
  secondDescription:
    '流经蒸馏厂旁的河流不仅为我们提供冷却用水，其上的水力发电机同步为蒸馏厂提供电力来源；蒸馏厂以生物质锅炉供应热能，并使用当地伐木业收集的木屑为燃料。此外，威士忌酿造过程中所产生的副产品也在艾德麦康半岛上得到妥善的再利用，例如我们将那些依然富含营养价值的麦渣（遗留在醣化槽中的东西）与其他蒸馏过程残余物混合后，提供给半岛上的畜牧业作为饲料使用。'
});

i18next.addResources('zt', 'GreenDistillery', {
  ardnamurchan: '艾德麥康',
  green: '綠色永續酒廠',
  firstDescription:
    '威士忌是天然的產品與環境息息相關，而我們確保所有的營運方式完美體現這個精神。艾德麥康酒廠所使用的電力與熱能需求均來自當地的可再生能源，我們致力於創造最小化的整體環境足跡，這在威士忌行業中難能可貴且獨樹一幟。',
  secondDescription:
    '流經酒廠旁的河流不僅為我們提供冷卻用水，其上的水力發電機同步為酒廠提供電力來源；酒廠以生物質鍋爐供應熱能，並使用當地伐木業收集的木屑為燃料。此外，威士忌釀造過程中所產生的副產品也在艾德麥康半島上得到妥善的再利用，例如我們將那些依然富含營養價值的麥渣（遺留在醣化槽中的東西）與其他蒸餾過程殘餘物混合後，提供給半島上的畜牧業作為飼料使用。'
});
