import { string } from 'prop-types';

import { MEASURES } from './constants';
import styles from './styles.module.scss';

function Measure({ measure }) {
  return measure ? <span className={styles.measure}>{MEASURES[measure]}</span> : null;
}

Measure.propTypes = {
  measure: string
};

export default Measure;
