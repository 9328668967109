import api, { createApiWithURL } from '#config/api';

export const getSignedUrl = file => {
  const [filename, extension] = file.name.split('.');
  return api.post('/files/signed_url', {
    name: filename,
    extension
  });
};

export const uploadFileToS3 = async ({ signedUrlData: payload, file }) => {
  try {
    const formData = new FormData();

    Object.keys(payload.fields).forEach(field => {
      formData.append(field, payload.fields[field]);
    });

    formData.append('file', file);

    const imageUploadResponse = await createApiWithURL(payload.url).post('', formData, {
      headers: { 'Content-type': 'multipart/form-data' }
    });

    return imageUploadResponse;
  } catch (err) {
    return { ok: false, data: err };
  }
};
