import { t } from 'i18next';

export const getSubStepValues =
  (subStepId, values, errors) =>
  (field, needsLabel = true) => ({
    key: field,
    ...(needsLabel ? { label: t(`${subStepId}:${field}`) } : {}),
    value: values?.[field],
    error: errors?.[field]
  });

export const getSubSteps = (target, ids, nextSubSteps, previousSubSteps) =>
  Object.values(ids).reduce(
    (prevValues, actValue) => [
      ...prevValues,
      {
        id: actValue,
        target,
        nextStep: nextSubSteps[actValue],
        previousStep: previousSubSteps[actValue]
      }
    ],
    []
  );
