import { arrayOf, bool, func, shape, string } from 'prop-types';
import cn from 'classnames';

import AdelphiField from '../AdelphiField';

import styles from './styles.module.scss';

function AdelphiFieldsRow({
  fields,
  register,
  setValue,
  fieldRowClassName,
  fieldContainerClassName,
  centered
}) {
  return (
    <div className={cn(styles.container, fieldRowClassName)}>
      {fields.map(({ key, Component, innerFieldContainerClassName, ...fieldProps }) => (
        <div
          key={key}
          className={cn(
            { [styles.fieldContainer]: !centered },
            fieldContainerClassName,
            innerFieldContainerClassName,
            {
              [styles.oneFieldContainer]: fields.length === 1
            }
          )}>
          <AdelphiField
            key={key}
            name={key}
            Component={Component}
            noMargin
            register={register}
            setValue={setValue}
            {...fieldProps}
          />
        </div>
      ))}
    </div>
  );
}

AdelphiFieldsRow.propTypes = {
  centered: bool,
  fieldContainerClassName: string,
  fieldRowClassName: string,
  fields: arrayOf(shape({})),
  register: func,
  setValue: func
};

export default AdelphiFieldsRow;
