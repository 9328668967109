import mixpanel from 'mixpanel-browser';

const init = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL);
};

const track = (eventName, properties = {}) => {
  mixpanel.track(eventName, properties);
};

export default {
  init,
  track
};
