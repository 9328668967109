import { getSubStepValues } from '#models/general/steps';
import AdelphiFieldsRow from '#components/business/AdelphiFieldsRow';
import FileInput from '#components/general/FileInput';
import Input from '#components/general/Input';
import { ACCEPT_ONLY_IMAGES } from '#constants/files';
import { getCurrentDateOnISOFormat } from '#utils/date';

import { NEW_PRODUCT_SUBSTEPS_IDS, PRODUCT_INFORMATION_FIELDS } from './constants';

export const getProductInformationFields = ({ values, errors }) => {
  const getProductInformationValues = getSubStepValues(
    NEW_PRODUCT_SUBSTEPS_IDS.PRODUCT_INFORMATION,
    values,
    errors
  );
  return [
    {
      key: 'firstRow',
      Component: AdelphiFieldsRow,
      fields: [
        {
          ...getProductInformationValues(PRODUCT_INFORMATION_FIELDS.PRODUCT_NAME),
          Component: Input,
          noMargin: true
        },
        {
          ...getProductInformationValues(PRODUCT_INFORMATION_FIELDS.PRODUCT_DATE),
          Component: Input,
          type: 'date',
          noMargin: true,
          value: values[PRODUCT_INFORMATION_FIELDS.PRODUCT_DATE]
            ? values[PRODUCT_INFORMATION_FIELDS.PRODUCT_DATE]
            : getCurrentDateOnISOFormat()
        }
      ]
    },
    {
      key: 'secondRow',
      Component: AdelphiFieldsRow,
      fields: [
        {
          ...getProductInformationValues(PRODUCT_INFORMATION_FIELDS.UNITS_TO_BE_CREATED),
          Component: Input,
          type: 'number',
          registerParams: { required: true, min: 1 },
          noMargin: true,
          disabled: !!values.legacyId
        },
        {
          ...getProductInformationValues(PRODUCT_INFORMATION_FIELDS.TOTAL_CIRCULATING_BOTTLES),
          Component: Input,
          type: 'number',
          noMargin: true
        }
      ]
    },
    {
      ...getProductInformationValues(PRODUCT_INFORMATION_FIELDS.PRODUCT_PICTURE),
      Component: FileInput,
      accept: ACCEPT_ONLY_IMAGES,
      noMargin: true,
      srcUrl: values?.productPictureURL,
      fileType: 'image'
    }
  ];
};
