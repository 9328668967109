/* eslint-disable id-length */
export const MODALS = {
  LOGIN_ERROR: 'loginError',
  SIDE_BAR: 'sideBar',
  NEW_PRODUCT: 'newProduct',
  URLS_GENERATED: 'urlsGenerated',
  BARLEY_INFORMATION: 'barleyInformation',
  MASHING_INFORMATION: 'mashingInformation',
  FERMENTATION_INFORMATION: 'fermentationInformation',
  DISTILLATION_INFORMATION: 'distillationInformation',
  MATURATION_INFORMATION: 'maturationInformation',
  NEW_PERIOD: 'newPeriod',
  NEW_MASH: 'newMash',
  MASH_STEP: 'mashStep',
  WASH_STEP: 'washStep',
  SPIRIT_STEP: 'spiritStep',
  NEW_ADMIN: 'newAdmin',
  ADELPHI_FEINT: 'adelphiFeint',
  ADELPHI_SPIRIT_REDUCTION: 'adelphiSpiritReduction',
  ADELPHI_CASK_FILLING: 'adelphiCaskFilling',
  CREATE_PRODUCTION_PROCESS: 'createProductionProcess',
  ADELPHI_STATEMENT: 'adelphiStatement',
  ADELPHI_STATEMENT_MASHING_AND_FERMENTATION_TOTALS: 'adelphiStatementMashingAndFermentationTotals',
  ADELPHI_STATEMENT_FINAL_CALCULATIONS: 'adelphiStatementFinalCalculations',
  CSVS_GENERATED: 'csvsGenerated',
  DELETE_RECORD: 'deleteRecord'
};
