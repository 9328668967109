import { completeTypes, createTypes, withPostFailure, withPostSuccess } from 'redux-recompose';
import { push } from 'connected-react-router';

import * as ProductsService from '#services/ProductsService';
import ModalActions from '#redux/Modal/actions';
import { MODALS } from '#redux/Modal/constants';
import { ROUTES } from '#constants/routes';

import { PRODUCTS_TARGETS } from './constants';
import { mapCreateProductData, mapEditProductData } from './mappers';
import { createProductWithFile, editProductWithFile, modProductImageAndMaturation } from './utils';

export const actions = createTypes(
  completeTypes({
    primaryActions: [
      'CREATE_PRODUCT',
      'GET_ALL_PRODUCTS',
      'EDIT_PRODUCT',
      'GENERATE_PRODUCT_URLS',
      'GENERATE_SEQUENTIAL_NUMBER',
      'GENERATE_LEGACY_SEQUENTIAL_NUMBER'
    ],
    ignoredActions: ['SET_CURRENT_PRODUCT']
  }),
  '@@PRODUCTS'
);

export const actionCreators = {
  createProduct: values => ({
    type: actions.CREATE_PRODUCT,
    target: PRODUCTS_TARGETS.CREATE_PRODUCT,
    payload: mapCreateProductData(values),
    service: ProductsService.createProduct,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(actionCreators.setCurrentProduct(null));
        dispatch(actionCreators.getAllProducts());
        dispatch(ModalActions.closeModal(MODALS.NEW_PRODUCT));
      }),
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  }),
  getAllProducts: () => ({
    type: actions.GET_ALL_PRODUCTS,
    target: PRODUCTS_TARGETS.PRODUCTS,
    service: ProductsService.getAllProducts,
    injections: [
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  }),
  createProductWithMaturation: ({ maturationFile, ...values }) =>
    createProductWithFile(maturationFile, values, 'maturationFileURL'),
  createProductWithImage: ({ productPicture, ...values }) =>
    createProductWithFile(productPicture, values, 'productPictureURL'),
  createProdImageAndMaturation: modProductImageAndMaturation('createProduct'),
  setCurrentProduct: payload => ({
    type: actions.SET_CURRENT_PRODUCT,
    target: PRODUCTS_TARGETS.CURRENT_PRODUCT,
    payload
  }),
  editProduct: values => ({
    type: actions.EDIT_PRODUCT,
    target: PRODUCTS_TARGETS.EDIT_PRODUCT,
    payload: mapEditProductData(values),
    service: ProductsService.editProduct,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(actionCreators.setCurrentProduct(null));
        dispatch(actionCreators.getAllProducts());
        dispatch(ModalActions.closeModal(MODALS.NEW_PRODUCT));
      }),
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  }),
  editProductWithMaturation: ({ maturationFile, ...values }) =>
    editProductWithFile(maturationFile, values, 'maturationFileURL'),
  editProductWithImage: ({ productPicture, ...values }) =>
    editProductWithFile(productPicture, values, 'productPictureURL'),
  editProdImageAndMaturation: modProductImageAndMaturation('editProduct'),
  generateProductURLs: id => ({
    type: actions.GENERATE_PRODUCT_URLS,
    target: PRODUCTS_TARGETS.GENERATE_PRODUCT_URLS,
    payload: id,
    service: ProductsService.generateProductURLs,
    injections: [
      withPostSuccess(dispatch => {
        dispatch(ModalActions.openModal(MODALS.URLS_GENERATED));
      }),
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  }),
  generateSequentialNumber: ({ productId, bottleNumber, mode }) => ({
    type: actions.GENERATE_SEQUENTIAL_NUMBER,
    target: PRODUCTS_TARGETS.PRODUCT_WITH_SEQUENTIAL_NUMBER,
    payload: { productId, bottleNumber, mode },
    service: ProductsService.generateSequentialNumber,
    successSelector: response => response.data.product,
    injections: [
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  }),
  generateLegacySequentialNumber: ({ bottleId, mode }) => ({
    type: actions.GENERATE_LEGACY_SEQUENTIAL_NUMBER,
    target: PRODUCTS_TARGETS.PRODUCT_WITH_SEQUENTIAL_NUMBER,
    payload: { bottleId, mode },
    service: ProductsService.generateLegacySequentialNumber,
    successSelector: response => response.data.product,
    injections: [
      withPostFailure(dispatch => {
        dispatch(push(ROUTES.ERROR.path));
      })
    ]
  })
};

export default actionCreators;
