import { ConnectedRouter } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { history } from '#redux/store';
import AuthActions from '#redux/Auth/actions';

import Routes from './components/ConnectedRoutes';

const AppRoutesContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AuthActions.init());
  }, [dispatch]);
  return (
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  );
};

export default AppRoutesContainer;
