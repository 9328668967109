import { useEffect, useState } from 'react';

import { useFocus } from '#hooks/useFocus';
import { FROM_0_TO_99_REGEX } from '#utils/string/regex';
import { validateStringWithRegex } from '#utils/string';

export const useInput = ({
  textArea,
  value,
  register,
  validatesKeyPress,
  error,
  handleChange,
  shouldTrim,
  name,
  setValue,
  maxLength,
  registerParams,
  needsRegex = false,
  customRegex = FROM_0_TO_99_REGEX
}) => {
  const { onBlur, ...restRegistration } = register(name, registerParams);

  const [inputValue, setInputValue] = useState(value);
  const { focus, isFocused, handleOnBlur, handleOnFocus } = useFocus(name);

  useEffect(() => {
    setInputValue(value);
    setValue?.(name, value);
  }, [value]);

  const handleKeyPress = event => {
    if (!!validatesKeyPress && !validatesKeyPress(event)) {
      event.preventDefault();
    }
  };

  const canBeChanged = inputChangedValue => !maxLength || inputChangedValue.length <= maxLength;

  const validateContentChange = inputChangedValue =>
    !needsRegex || (needsRegex && validateStringWithRegex(inputChangedValue, customRegex));

  const onInputChange = event => {
    const newValue = event.target.value;
    const inputChangedValue =
      canBeChanged(newValue) && validateContentChange(newValue) ? newValue : inputValue;
    setInputValue(inputChangedValue);
    setValue(name, inputChangedValue);
    handleChange?.(event);
  };

  const handleOnBlurInput = handleOnBlur(error, event => {
    const text = shouldTrim ? value?.trim() : inputValue;
    if (setValue) {
      setValue(name, text);
    } else {
      setInputValue(text);
    }

    onBlur?.(event);
  });

  const Component = textArea ? 'textarea' : 'input';

  return {
    Component,
    handleOnBlurInput,
    onInputChange,
    handleKeyPress,
    focus,
    focused: isFocused,
    handleOnFocus,
    restRegistration,
    inputValue
  };
};
