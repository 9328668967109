import { getSubStepValues } from '#models/general/steps';
import { getCheckboxesPercentagesRows } from '#models/general/fields/checkboxPercentage';

import { NEW_PRODUCT_SUBSTEPS_IDS } from './constants';

export const BOTTLED_BY_OPTIONS = {
  LEWIS_HAMILTON: 'lewisHamilton',
  KELLY_COMBE: 'kellyCombe',
  CARNIE_HAMILTON: 'carnieHamilton',
  JESS_HAMILTON: 'jessHamilton',
  JASON_MCCAFFERTY: 'jasonMcCafferty',
  KEITH_JOHNSTON: 'keithJohnston',
  JENNY_KARLSSON: 'jennyKarlsson',
  HOLLY_MCCAFFERTY: 'hollyMcCafferty',
  REECE_DONALDSON: 'reeceDonaldson',
  ANDY_WILLIAMSON: 'andyWilliamson',
  ANDREW_COLMAN: 'andrewColman'
};

export const getBottledByFields = ({ values, errors }) => {
  const getBottledByValues = getSubStepValues(NEW_PRODUCT_SUBSTEPS_IDS.BOTTLED_BY, values, errors);
  return getCheckboxesPercentagesRows(Object.values(BOTTLED_BY_OPTIONS), getBottledByValues, values);
};
