import { ROUTES } from '#constants/routes';
import { getCurrentRoute } from '#utils/routes';

export const isActualPath = path => () => window.location.pathname === path;

export const getLoggedUserRedirect = ({ privateRoute, access, defaultPath }) =>
  !(privateRoute && access) && defaultPath;

export const isActualPathHome = () => window.location.pathname === ROUTES.HOME.path;

export const isValidActualPath = () =>
  Object.values(ROUTES)
    .map(({ path }) => path)
    .includes(window.location.pathname);

export const showActualRoute = (tokenManager, pathname) => {
  const { publicRoute } = getCurrentRoute(pathname) || {};
  if (publicRoute) {
    return '';
  }
  return 'HOME';
};
