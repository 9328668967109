import { useLocation, withRouter } from 'react-router';

import { useRedirect } from '#hooks/useRedirect';
import { showActualRoute } from '#models/routes';
import LocalStorage from '#services/LocalStorageService';

import AuthenticatedRouteLayout from './layout';

function AuthenticatedRoute(props) {
  const handleRedirect = useRedirect();
  const tokenManager = LocalStorage.getTokenManager();
  const { pathname } = useLocation();
  return (
    <AuthenticatedRouteLayout
      handleRedirect={handleRedirect}
      redirectRoute={showActualRoute(tokenManager, pathname)}
      {...props}
    />
  );
}

export default withRouter(AuthenticatedRoute);
