/* eslint-disable react/button-has-type */
import React from 'react';
import { string, boolean, bool, node } from 'prop-types';
import cn from 'classnames';

import LoadingWrapper from '../LoadingWrapper';
import { SPINNER_KEYS } from '../LoadingWrapper/components/Loading/constants';

import { buttonTypePropType } from '#propTypes/buttons';
import { refProptype } from '#propTypes/ref';

import styles from './styles.module.scss';
import { LOADING_STYLE, WHITE_LOADING_STYLE } from './constants';

function Button({
  children,
  type = 'button',
  disabled,
  loading,
  loadingClassName,
  primary,
  secondary,
  tertiary,
  className,
  Icon,
  iconClassName,
  buttonRef,
  ...props
}) {
  const isDisabled = loading || disabled;
  return (
    <button
      type={type}
      disabled={isDisabled}
      ref={buttonRef}
      className={cn(
        {
          [styles.primaryButton]: primary,
          [styles.secondaryButton]: secondary,
          [styles.tertiaryButton]: tertiary,
          [styles.primaryDisabledButton]: primary && isDisabled,
          [styles.secondaryDisabledButton]: secondary && isDisabled,
          [styles.tertiaryDisabledButton]: tertiary && isDisabled
        },
        className
      )}
      {...props}>
      <LoadingWrapper
        loading={loading}
        className={cn(styles.loadingContainer, loadingClassName)}
        lottieStyle={primary ? WHITE_LOADING_STYLE : LOADING_STYLE}
        type={primary && SPINNER_KEYS.WHITE_LOADING}>
        {Icon && <Icon className={cn(styles.icon, iconClassName)} />}
        {children}
      </LoadingWrapper>
    </button>
  );
}

Button.propTypes = {
  buttonRef: refProptype,
  className: string,
  disabled: bool,
  Icon: node,
  iconClassName: string,
  loading: boolean,
  loadingClassName: string,
  primary: bool,
  secondary: bool,
  tertiary: bool,
  type: buttonTypePropType
};

export default Button;
