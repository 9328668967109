export const includes = (arr1, arr2) => arr1.every(element => arr2.includes(element));

export const isLastItemArray = (array, index) => index === array.length - 1;

const concat = (x, y) => x.concat(y);

export const flatMap = (f, xs) => xs.map(f).reduce(concat, []);

export const flattened = arr => [].concat(...arr);

export const isEmpty = array => array.length === 0;
