const WHITE_LOADING_SIZE = 40;

const LOADING_SIZE = 35;

export const WHITE_LOADING_STYLE = {
  width: WHITE_LOADING_SIZE,
  height: WHITE_LOADING_SIZE
};

export const LOADING_STYLE = {
  width: LOADING_SIZE,
  height: LOADING_SIZE
};
