/* eslint-disable max-lines */
import i18next from 'i18next';

i18next.addResources('en', 'Steps', {
  wash: 'Wash',
  mash: 'Mash',
  spirit: 'Spirit'
});

i18next.addResources('en', 'AdelphiStatementsSpiritAndFeints', {
  previousRemnant: 'Previous Remnant',
  srwv: 'SRWV {{fillId}}',
  remnant: 'Remnant {{fillId}}',
  dip: 'Dip',
  bulk: 'Bulk',
  lwfr: 'LWFR',
  dillutedSpirit: 'Diluted Spirit',
  totalLossGain: 'Total Loss/Gain'
});
