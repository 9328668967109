import i18next from 'i18next';

i18next.addResources('en', 'BottleMainDescription', {
  description:
    'This whisky is honed and inspired by the finest\nand most spectacular location of any distillery in\nScotland – The Ardnamurchan Peninsula',
  bottledBy: 'Bottled by',
  bottledOn: 'Bottled on',
  ardnamurchan: 'Ardnamurchan, The Green Distillery',
  tasting: 'Our Tasting Notes',
  discover: 'Discover Complete Traceability'
});

i18next.addResources('fr', 'BottleMainDescription', {
  description:
    'Ce whisky est façonné et inspire par\nun des endroits les plus spectaculaires\nd’Ecosse: ARDNAMURCHAN!',
  bottledBy: 'Mis en bouteille par',
  bottledOn: 'Mis en bouteille le…',
  ardnamurchan: 'Ardnamurchan, la distillerie verte',
  tasting: 'Nos notes de dégustation',
  discover: 'Une traçabilité de A à Z'
});

i18next.addResources('it', 'BottleMainDescription', {
  description:
    'Questo whisky si ispira al luogo più bello\ne spettacolare di qualsiasi altra distilleria in\nScozia: Ardnamurchan',
  bottledBy: 'Imbottigliato da',
  bottledOn: 'Data imbottigliamento',
  ardnamurchan: 'Ardnamurchan, La Distilleria Green',
  tasting: 'Le nostre note di degustazione',
  discover: 'Scopri la tracciabilità completa'
});

i18next.addResources('de', 'BottleMainDescription', {
  description:
    'Dieser Whisky wird von einem der feinsten\nund spektakulärsten Orte einer Destillerie in\nSchottland verfeinert und inspiriert – Ardnamurchan',
  bottledBy: 'Abgefüllt von',
  bottledOn: 'Abgefüllt am',
  ardnamurchan: 'Ardnamurchan, Die Grüne Destillerie',
  tasting: 'Unsere Verkostungsnotizen',
  discover: 'Entdecken Sie die vollständige Rückverfolgbarkeit'
});

i18next.addResources('zh', 'BottleMainDescription', {
  description: '此威士忌的灵感来源于苏格兰蒸馏厂中拥有最好最壮观地理环境的－艾德麦康（Ardnamurchan）。',
  bottledBy: '装瓶操作员',
  bottledOn: '装瓶日期',
  ardnamurchan: '艾德麦康-绿色永续蒸馏厂',
  tasting: '我们的品饮笔记',
  discover: '探索完整的溯源纪录'
});

i18next.addResources('zt', 'BottleMainDescription', {
  description: '此威士忌的靈感來源於蘇格蘭酒廠中擁有最好最壯觀地理環境的－艾德麥康（Ardnamurchan）。',
  bottledBy: '裝瓶操作員',
  bottledOn: '裝瓶日期',
  ardnamurchan: '艾德麥康-綠色永續酒廠',
  tasting: '我們的品飲筆記',
  discover: '探索完整的溯源紀錄'
});
