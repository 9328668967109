import i18next from 'i18next';

i18next.addResources('en', 'CompleteTraceability', {
  yourBottle: 'Your bottle of',
  whisky: 'whisky',
  trueTraceability: 'true traceability',
  description:
    'Each step in creating our {{- bottleTitle}} has been carefully recorded and written to the blockchain as a guarantee of complete transparency and authenticity. It means you can trace each step and every process that went into creating this iconic whisky.',
  barley: '{{number}}. The Barley',
  mashing: '{{number}}. Mashing',
  fermentation: '{{number}}. Fermentation',
  distillation: '{{number}}. Distillation',
  maturation: '{{number}}. Maturation',
  barleyDescription:
    'Most of our barley grows in West Fife, near our head office, with the rest supplied and malted within Scotland. We use both peated and unpeated malted barley in the production of our whisky. Our malted barley is supplied by Bairds Maltings.',
  mashingDescription:
    'Our mashtun was individually designed to fit both our distillery, and the narrowest bridge (3.2m) when being transported across the Ardnamurchan peninsula. The malted barley is added to the mashtun along with hot water to start the mashing process. The water is heated by our biomass boiler using wood chips from sustainable local forestry. Once our wort is ready it gets transferred to our washbacks for fermentation. The draff left over from the mashing is mixed with the evaporated pot ale and makes a nutritious animal feed for the local farm.',
  fermentationDescription:
    'If you ask any distillery that ferments in wooden washbacks what style of washbacks are best, they will tell you wooden ones are best. If you ask a distillery using stainless steel washbacks the same question, they will tell you stainless steel is best. At Ardnamurchan we opted to use both styles, we have four wooden ones, two made from oak and two from Oregon pine, and three made from stainless steel.',
  distillationDescription:
    'Our spirit is distilled using traditional copper stills, the wash still holds 10,000 liters and the spirit still, which has a neck with a boil ball for extra reflux, holds 6,000 litres. We use standard shell and tube condensers, unusually located outside the building, to turn the spirit vapour into liquid. Our new make spirit is cut at around 75% ABV. We use water from the Glenmore spring to dilute the spirit down to 63.5% ABV before filling the casks',
  maturationDescription:
    'Unusually, these days, we have our own dunnage warehouses at the distillery. Our whisky matures right next to where it was made, on the Ardnamurchan peninsula, in close proximity to the North Atlantic Ocean (Ardnamurchan translates as “Headland of the Great Seas”). We believe the rugged west coast air gives our whisky a certain element that cannot be replicated elsewhere.',
  barleyButton: 'Barley variety used for this batch.',
  mashingButton: 'Mashwater temperature for this batch.',
  fermentationButton: 'Hours of fermentation for this batch.',
  distillationButton: 'Spirit hydrometer readings for this batch.',
  maturationButton: 'See the cask data for this batch.'
});

i18next.addResources('fr', 'CompleteTraceability', {
  yourBottle: 'Votre bouteille de',
  whisky: 'whisky',
  trueTraceability: 'la transparence totale !',
  description:
    "Chaque étape de la création de notre {{- bottleTitle}} a été soigneusement enregistrée et écrite dans la blockchain comme garantie d'une transparence et d'une authenticité totales. Ainsi, vous pouvez retracer le cycle de vie et revisiter chaque processus de la création de ce whisky emblématique.",
  barley: "{{number}}. L'orge",
  mashing: '{{number}}. Le brassage',
  fermentation: '{{number}}. La fermentation',
  distillation: '{{number}}. La distillation',
  maturation: '{{number}}. La maturation',
  barleyDescription:
    "La majeure partie de notre orge provient d’une ferme familiale dans le West Fife, près de nos bureaux. Le reste est cultivé et malté en Écosse. Nous utilisons à la fois de l'orge maltée tourbée et non tourbée dans la production de notre {{- bottleTitle}}. Notre orge maltée est fournie par Bairds Maltings.",
  mashingDescription:
    "Notre mashtun (cuve de brassage) a été conçu à la fois pour s'adapter à notre distillerie et pour pouvoir traverser le pont le plus étroit (3,2 m) lors de son transport en camion à travers la péninsule d'Ardnamurchan pendant la construction de la distillerie.  L'orge maltée est ajoutée au mashtun avec de l'eau chaude pour démarrer le processus de brassage. L'eau est chauffée par notre chaudière biomasse utilisant des copeaux de bois issus d'une sylviculture locale durable. Une fois que notre moût est prêt, il est transféré dans nos washbacks (cuves de fermentation) pour la fermentation. La drêche (ce qui reste dans la cuve de brassage) est mélangée à la pot ale (la bière d’alambic) évaporée et constitue un aliment nutritif pour les animaux de la ferme locale.",
  fermentationDescription:
    "Le moût est ensuite introduit dans de grandes cuves (washbacks), où il est fermenté par addition de levures d'origine naturelle. Ces levures attaquent le sucre du moût et le convertissent en alcool brut. Notre fermentation dure 68-92 heures et produit un liquide appelé wash qui contient une certaine quantité d'alcool faible, des matières non fermentescibles, et des sous-produits de fermentation. Si vous demandez à une distillerie qui fermente dans des washback (cuve de fermentation) en bois quel style de washback sont les meilleurs, ils vous diront que ceux en bois sont les meilleurs. Si vous posez la même question à une distillerie utilisant des cuves en acier inoxydable, ils vous diront que c’est l’inox le meilleur. Chez Ardnamurchan, nous avons choisi d'utiliser les deux styles. Nous en avons quatre en bois : une en chêne, trois en pin d'Oregon et trois en acier inoxydable.",
  distillationDescription:
    "Notre spiritueux est distillé à l'aide d'alambics en cuivre traditionnels ; le premier alambic (wash still) contient 10 000 litres et le deuxième (spirit still), qui a un col avec une boule d'ébullition pour un reflux supplémentaire, contient 6 000 litres. Nous utilisons des condenseurs standard à coque et à tube, exceptionnellement situés à l'extérieur du bâtiment, pour transformer la vapeur d'alcool en liquide. Notre distillat (new make spirit) est réduit à environ 75% ABV. Nous utilisons l'eau de la source Glenmore pour diluer l'alcool à 63,5 % ABV avant de remplir les fûts.",
  maturationDescription:
    "Abritant le point le plus à l'ouest du continent britannique, la péninsule d'Ardnamurchan est une frontière préservée entre les Highlands et l'océan Atlantique. Nous pensons que l'air de cette côte ouest sauvage et reculée confère à notre whisky une qualité unique. Juste à côté de la distillerie, nos fûts vieillissent dans des entrepôts en terre battu ; ce type d’entrepôt est assez inhabituel de nos jours. ",
  barleyButton: "Variété d'orge utilisée pour cette mise en bouteille ",
  mashingButton: 'La temperature de l’eau de brassage de cette cuvée ',
  fermentationButton: 'Heures de fermentation pour cette cuvée ',
  distillationButton: "Relevés de l'hydromètre à alcool pour cette cuvée ",
  maturationButton: 'Voir les données du fût pour ce lot'
});

i18next.addResources('it', 'CompleteTraceability', {
  yourBottle: 'La tua bottiglia d',
  whisky: 'whisky',
  trueTraceability: 'vera tracciabilità',
  description:
    'Ogni passaggio nella creazione del nostro {{- bottleTitle}} è stato accuratamente registrato e scritto sulla blockchain come garanzia di completa trasparenza e autenticità. Significa che puoi tracciare ogni passaggio e ogni processo che ha portato alla creazione di questo whisky iconico.',
  barley: "{{number}}. L'orzo",
  mashing: '{{number}}. Mashing',
  fermentation: '{{number}}. Fermentazione',
  distillation: '{{number}}. Distillazione',
  maturation: '{{number}}. Maturazione',
  barleyDescription:
    "La maggior parte del nostro orzo cresce nel West Fife, vicino alla nostra sede centrale, mentre il resto viene fornito e maltato in Scozia. Per la produzione del nostro {{- bottleTitle}} utilizziamo sia malto d'orzo torbato che non torbato. Il nostro malto d'orzo è fornito da Bairds Maltings.",
  mashingDescription:
    "Il nostro mashtun è stato progettato individualmente per adattarsi sia alla nostra distilleria che al ponte più stretto (3,2 m) durante il trasporto attraverso la penisola di Ardnamurchan. L'orzo maltato viene aggiunto al mashtun insieme ad acqua calda per avviare il processo di ammostamento. L'acqua viene riscaldata dalla nostra caldaia a biomassa utilizzando trucioli di legno provenienti da silvicoltura locale sostenibile. Una volta che il nostro mosto è pronto, viene trasferito nei nostri washback per la fermentazione. Il residuo rimasto dall'ammostamento costituisce un nutriente alimento per gli allevamenti locali.",
  fermentationDescription:
    "Se chiedi a qualsiasi distilleria che fermenta in tini di legno quale tipo di materiale è il migliore, ti diranno che quelli di legno sono i migliori. Se fai la stessa domanda a una distilleria che utilizza vasche in acciaio inossidabile, ti diranno che lacciaio inossidabile è il migliore. Ad Ardnamurchan abbiamo scelto di utilizzare entrambi gli stili. Ne abbiamo quattro in legno - uno in quercia e tre in pino dell'Oregon - e tre in acciaio inossidabile.",
  distillationDescription:
    "Il nostro spirito viene distillato utilizzando tradizionali alambicchi di rame, l'alambicco “wash still” contiene 10.000 litri e lo “spirit still”, che ha un collo con una sfera di ebollizione per un riflusso extra, contiene 6.000 litri. Utilizziamo condensatori a fascio tubiero standard, collocati insolitamente all'esterno dell'edificio, per trasformare il vapore alcolico in liquido. Il nostro new make è tagliato a circa il 75% di alcol. Usiamo l'acqua della sorgente di Glenmore per diluire il distillato fino al 63,5% di alcol prima di riempire le botti",
  maturationDescription:
    "La penisola di Ardnamurchan è un tratto di terra incontaminato tra le Highlands e l'Oceano Atlantico, collocato sul punto più occidentale sulla terraferma del Regno Unito. Sentiamo che l'aria di questa costa selvaggia e remota conferisce una qualità unica al nostro whisky. Le nostre botti sono invecchiate nei tradizionali dunnage, proprio accanto alla distilleria.",
  barleyButton: 'Varietà di orzo utilizzata per questo lotto',
  mashingButton: "Temperatura dell'acqua nel mashtun per questo lotto",
  fermentationButton: 'Ore di fermentazione per questo lotto',
  distillationButton: "Letture dell'idrometro per questo lotto",
  maturationButton: 'Guarda i dati della botte per questo lotto'
});

i18next.addResources('de', 'CompleteTraceability', {
  yourBottle: 'Ihre Flasche',
  whisky: 'whisky',
  trueTraceability: 'echte Rückverfolgbarkeit',
  description:
    'Jeder Schritt bei der Herstellung unseres {{- bottleTitle}} wurde sorgfältig aufgezeichnet und als Garantie für vollständige Transparenz und Authentizität in der Blockchain dokumentiert. Das bedeutet, dass Sie jeden Schritt und jeden Prozess nachvollziehen können, der zur Herstellung dieses ikonischen Whiskys geführt hat.',
  barley: '{{number}}. Die Gerste',
  mashing: '{{number}}. Maischen',
  fermentation: '{{number}}. Gärung',
  distillation: '{{number}}. Destillation',
  maturation: '{{number}}. Reifung',
  barleyDescription:
    'Der Großteil unserer Gerste wächst in West Fife, in der Nähe unseres Hauptsitzes, der Rest wird innerhalb Schottlands geliefert und gemälzt. Für die Herstellung unseres Whiskys verwenden wir sowohl rauchige als auch ungetorfte gemälzte Gerste. Beim Mälzen unserer Gerste arbeiten wir ausschließlich mit schottischen Mälzern zusammen.',
  mashingDescription:
    'Unser Mashtun wurde individuell entworfen, um sowohl in unsere Destillerie als auch über die schmalste Brücke (3,2 m) zu passen, wenn er über die Ardnamurchan-Halbinsel transportiert wird. Die gemälzte Gerste wird zusammen mit heißem Wasser in den Maischbottich gegeben, um den Maischvorgang zu starten. Das Wasser wird von unserem Biomassekessel mit Hackschnitzeln aus nachhaltiger heimischer Forstwirtschaft erhitzt. Sobald unsere Würze fertig ist, wird sie zur Fermentation in unsere Washbacks überführt. Der Trester, der beim Maischen übrig bleibt, wird mit dem verdunsteten Pot Ale gemischt und ergibt ein nahrhaftes Tierfutter für die lokale Farm.',
  fermentationDescription:
    'Wenn Sie irgendeine Destillerie fragen, die in hölzernen Washbacks fermentiert, welche Art von Washbacks am besten sind, werden sie Ihnen sagen, dass hölzerne Washbacks am besten sind. Wenn Sie einer Destillerie, die Edelstahl-Washbacks verwendet, die gleiche Frage stellen, werden sie Ihnen sagen, dass Edelstahl am besten ist. Bei Ardnamurchan haben wir uns für beide Stile entschieden. Wir haben vier aus Holz, einen aus Eiche und drei aus Douglasie und drei aus Edelstahl.',
  distillationDescription:
    'Unsere Spirituose wird mit traditionellen Kupferbrennblasen destilliert, die Wash Still fasst 10.000 Liter und die Spirit Still, die einen Hals mit einer Siedekugel für zusätzlichen Rückfluss hat, fasst 6.000 Liter. Wir verwenden standardmäßige Rohrbündelkondensatoren, die sich ungewöhnlich außerhalb des Gebäudes befinden, um den Spiritusdampf in Flüssigkeit umzuwandeln. Wir verwenden Wasser aus der Glenmore-Quelle, die direkt über der Destillerie fließt, um die Spirituose auf 63,5 % ABV zu verdünnen, bevor wir die Fässer füllen',
  maturationDescription:
    'Ungewöhnlicherweise heutzutage haben wir unsere eigenen Stauholzlager in der Brennerei. Unser Whisky reift direkt dort, wo er hergestellt wurde, auf der Halbinsel Ardnamurchan, in unmittelbarer Nähe zum Nordatlantik (Ardnamurchan bedeutet übersetzt „Landzunge der großen Meere“). Wir glauben, dass die raue Westküstenluft unserem Whisky ein gewisses Element verleiht, das anderswo nicht nachgebildet werden kann.',
  barleyButton: 'Für diese Charge verwendete Gerstensorte',
  mashingButton: 'Maischwassertemperatur für diese Charge',
  fermentationButton: 'Stunden Gärung für diese Charge',
  distillationButton: 'Alkohol-Aräometer-Messwerte für diese Charge',
  maturationButton: 'Siehe die Fassdaten für diese Charge'
});

i18next.addResources('zh', 'CompleteTraceability', {
  yourBottle: '属于您的',
  whisky: '威士忌',
  trueTraceability: '溯源纪录',
  description:
    '作为完全公开透明与真实性的保证，我们单一麦芽威士忌的每一个创作步骤皆被仔细的纪录并写入区块链资料库中。这意味着您可以追踪此款标志性商品诞生时所经历的每一个过程。',
  barley: '{{number}}. 大麦',
  mashing: '{{number}}. 糖化',
  fermentation: '{{number}}. 发酵',
  distillation: '{{number}}. 蒸馏',
  maturation: '{{number}}. 陈酿',
  barleyDescription:
    '我们所使用的原料大麦，绝大多数生长于公司总部所在地-西法夫郡（West Fife）周围的农场，其余少数则来自苏格兰他区的供应商。在生产过程中我们会使用无泥煤大麦及泥煤大麦。而我们坚持仅与苏格兰当地的发麦厂合作，他们协助我们进行大麦的处理与发麦。',
  mashingDescription:
    '我们的糖化槽是针对蒸馏厂规格特别订制的，以确保其尺寸在运输过程中得以顺利通过艾德麦康半岛最狭窄的桥梁(3.2米宽)。糖化这个步骤是将磨碎的发芽大麦与热水加入糖化槽中煮出麦芽汁。我们使用当地永续伐木业回收的木屑为燃料，以生物质锅炉供应热能。完成的麦芽汁将投入发酵槽进行下一阶段的发酵作业，而糖化槽中残留的麦渣则提供给当地的畜牧业作为饲料使用',
  fermentationDescription:
    '如果你询问任何一间使用木制发酵槽的蒸馏厂“什么材质的发酵槽是最好的？”没有意外的话，他们会回答你木制的是最好的。当你询问一间使用不锈钢发酵槽的蒸馏厂同样的问题时，得到的答案会是不锈钢制是最好的。在艾德麦康蒸馏厂，我们选择同时拥有两种风格。我们有四个木制的发酵槽（一个橡木制，三个花旗松木制。）以及三个不锈钢发酵槽。',
  distillationDescription:
    '我们的烈酒蒸馏使用传统的铜制蒸馏器，蒸馏厂拥有一座10,000公升容量的初馏器，及一座6,000公升的再馏器，再馏器的颈部特别增加了圆球造型的设计，用以创造更多的回流与交互作用。冷却方面我们使用标准的壳管式冷凝器将酒精蒸汽转化回液体。与许多酒厂不同的是，我们的冷凝器延伸架设于酒厂的外部墙面。在入桶前，我们会使用来自酒厂旁的Glenmore泉水将烈酒其稀释至63.5度。',
  maturationDescription:
    '艾德麦康蒸馏厂拥有自己的陈酿仓库，就坐落于蒸馏厂旁，这在今日是十分独特与少见的。我们坚持让所有的威士忌酒桶留在靠近北大西洋畔的艾德麦康半岛上熟成（Ardnamurchan 意为“大海的岬角”），我们相信半岛上多变的西海岸气候将为我们的威士忌赋予独特且无法复制的风味。',
  barleyButton: '本批次使用的大麦品种',
  mashingButton: '本批次糖化所使用的热水温度',
  fermentationButton: '本批次的平均发酵时间',
  distillationButton: '本批次的酒精比重计检测纪录',
  maturationButton: '查看本批次的酒桶资讯'
});

i18next.addResources('zt', 'CompleteTraceability', {
  yourBottle: '屬於您的',
  whisky: '威士忌',
  trueTraceability: '溯源紀錄',
  description:
    '作為完全公開透明與真實性的保證，我們單一麥芽威士忌的每一個創作步驟皆被仔細的紀錄並寫入區塊鏈資料庫中。這意味著您可以追蹤此款標誌性商品誕生時所經歷的每一個過程。',
  barley: '{{number}}. 大麥',
  mashing: '{{number}}. 糖化',
  fermentation: '{{number}}. 發酵',
  distillation: '{{number}}. 蒸餾',
  maturation: '{{number}}. 熟成',
  barleyDescription:
    '我們所使用的原料大麥，絕大多數生長於公司總部所在地-西法夫郡（West Fife）周圍的農場，其餘少數則來自蘇格蘭他區的供應商。在生產過程中我們會使用無泥煤大麥及泥煤大麥。而我們堅持僅與蘇格蘭當地的發麥廠合作，他們協助我們進行大麥的處理與發麥。',
  mashingDescription:
    '我們的糖化槽是針對酒廠規格特別訂製的，以確保其尺寸在運輸過程中得以順利通過艾德麥康半島最狹窄的橋樑(3.2米寬)。糖化這個步驟是將磨碎的發芽大麥與熱水加入糖化槽中煮出麥芽汁。我們使用當地永續伐木業回收的木屑為燃料，以生物質鍋爐供應熱能。完成的麥芽汁將投入發酵槽進行下一階段的發酵作業，而糖化槽中殘留的麥渣則提供給當地的畜牧業作為飼料使用。',
  fermentationDescription:
    '如果你詢問任何一間使用木製發酵槽的酒廠「什麼材質的發酵槽是最好的？」沒有意外的話，他們會回答你木製的是最好的。當你詢問一間使用不銹鋼發酵槽的酒廠同樣的問題時，得到的答案會是不銹鋼製是最好的。在艾德麥康酒廠，我們選擇同時擁有兩種風格。我們有四個木製的發酵槽（一個橡木製，三個花旗松木製。）以及三個不鏽鋼發酵槽。',
  distillationDescription:
    '我們的烈酒蒸餾使用傳統的銅製蒸餾器，酒廠擁有一座10,000公升容量的初餾器，及一座6,000公升的再餾器，再餾器的頸部特別增加了圓球造型的設計，用以創造更多的回流與交互作用。冷卻方面我們使用標準的殼管式冷凝器將酒精蒸汽轉化回液體。與許多酒廠不同的是，我們的冷凝器延伸架設於酒廠的外部牆面。在入桶前，我們會使用來自酒廠旁的Glenmore泉水將烈酒其稀釋至63.5度。',
  maturationDescription:
    '艾德麥康酒廠擁有自己的熟成倉庫，就坐落於酒廠旁，這在今日是十分獨特與少見的。我們堅持讓所有的威士忌酒桶留在靠近北大西洋畔的艾德麥康半島上熟成（Ardnamurchan 意為“大海的岬角”），我們相信半島上多變的西海岸氣候將為我們的威士忌賦予獨特且無法複製的風味。',
  barleyButton: '本批次使用的大麥品種',
  mashingButton: '本批次糖化所使用的熱水溫度',
  fermentationButton: '本批次的平均發酵時間',
  distillationButton: '本批次的酒精比重計檢測紀錄',
  maturationButton: '查看本批次的酒桶資訊'
});
