import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { PRODUCTS_DEFAULT_STATE } from './constants';

const completedState = completeState(PRODUCTS_DEFAULT_STATE);

const reducerDescription = {
  primaryActions: [
    actions.CREATE_PRODUCT,
    actions.GET_ALL_PRODUCTS,
    actions.EDIT_PRODUCT,
    actions.GENERATE_PRODUCT_URLS,
    actions.GENERATE_SEQUENTIAL_NUMBER,
    actions.GENERATE_LEGACY_SEQUENTIAL_NUMBER
  ],
  override: { [actions.SET_CURRENT_PRODUCT]: onReadValue() }
};

const reducer = createReducer(new Immutable(completedState), completeReducer(reducerDescription));

export default reducer;
