import api from '#config/api';

export const createProduct = values => api.post('/products', values);

export const getAllProducts = () => api.get('/products');

export const editProduct = ({ id, ...values }) => api.put(`/products/${id}`, values);

export const generateProductURLs = id => api.post(`/products/${id}/urls`, {});

export const generateSequentialNumber = ({ productId, bottleNumber, mode }) =>
  api.put(
    `/products/${productId}/${bottleNumber}/generate_sequential_number${mode ? `?mode=${mode}` : ''}`,
    {}
  );

export const generateLegacySequentialNumber = ({ bottleId, mode }) =>
  api.put(`/products/newBottles-detail/${bottleId}${mode ? `?mode=${mode}` : ''}`, {});
