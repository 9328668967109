import { EMAIL_REGEX } from '#constants/regex';

import { URL_REGEX } from './string/regex';

export const isValidEmail = email => !email || EMAIL_REGEX.test(email);

export const trimValidation = errorMessage => ({
  validate: value => !!value.trim().length || errorMessage
});

export const requiredValidation = errorMessage => ({
  required: errorMessage
});

export const isValidWebsite = url => !url || URL_REGEX.test(url);
