import i18next from 'i18next';

i18next.addResources('en', 'BottleSocials', {
  importantInfo: 'Our whisky is always unchill-filtered\nand bottled at its natural colour. ',
  weAreSocial: "We're social,\nconnect with us"
});

i18next.addResources('fr', 'BottleSocials', {
  importantInfo: 'Notre whisky n’est jamais filtré à froid \net aucun colorant n’y est ajouté. ',
  weAreSocial: 'Nous sommes sur les réseaux sociaux,\nconnectez-vous avec nous !'
});

i18next.addResources('it', 'BottleSocials', {
  importantInfo: 'Il nostro whisky non è mai filtrato a freddo,\nné è stato aggiunto alcun colorante. ',
  weAreSocial: 'Siamo social,\nconnettiti con noi!'
});

i18next.addResources('de', 'BottleSocials', {
  importantInfo: 'Unser Whisky wird immer ungekühlt gefiltert und mit seiner natürlichen Farbe abgefüllt.',
  weAreSocial: 'Wir sind sozial,\nverbinden Sie sich mit uns'
});

i18next.addResources('zh', 'BottleSocials', {
  importantInfo: '我们的威士忌坚持不使用冷凝过滤也不添加焦糖着色剂 ',
  weAreSocial: '欢迎关注我们'
});

i18next.addResources('zt', 'BottleSocials', {
  importantInfo: '我們的威士忌堅持不使用冷凝過濾也不添加焦糖著色劑 ',
  weAreSocial: '歡迎追蹤我們'
});
