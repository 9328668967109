import i18next from 'i18next';

import { IMPORTER_FIELDS } from '#models/business/NewProduct/constants';

i18next.addResources('en', 'Importer', {
  title: 'Importer',
  [IMPORTER_FIELDS.IMPORTERS_NAME]: "Importer's Name",
  [IMPORTER_FIELDS.WEBSITE]: 'Website',
  [IMPORTER_FIELDS.EMAIL_ADDRESS]: 'Email Address'
});
