import { t } from 'i18next';
import cn from 'classnames';

import { getSubStepValues } from '#models/general/steps';
import Input from '#components/general/Input';
import Button from '#components/general/Button';
import { getSelectField } from '#utils/select';
import AdelphiFieldsRow from '#components/business/AdelphiFieldsRow';
import TrashImage from '#assets/delete.svg';

import { NEW_PRODUCT_SUBSTEPS_IDS, TASTING_NOTES_FIELDS } from './constants';
import styles from './styles.module.scss';
import languagesNames from './languagesNames.json';

const createLanguageOptions = () =>
  Object.entries(languagesNames).map(([code, languages]) => ({
    label: languages,
    value: code,
    id: code
  }));

const getLanguageId = (options, language) => options?.find(({ label }) => label === language)?.id;
const getLanguageById = (options, id) => options?.find(({ id: otherId }) => otherId === id)?.label;

const newTranslation = (language, removeTranslation, id) => (getTastingNotesValues, getCurrentTranslations) =>
  [
    {
      key: `title-${id}`,
      Component: AdelphiFieldsRow,
      centered: true,
      fields: [
        {
          key: `removeTranslation-${id}`,
          Component: Button,
          children: <img src={TrashImage} className={styles.delete} />,
          onClick: () => removeTranslation(getCurrentTranslations)
        },
        {
          Component: () => (
            <div className={cn(styles.subStepTitle)}>
              {t(`${NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES}:tastingNotesSubtitle`, { language })}
            </div>
          )
        }
      ]
    },
    {
      ...getTastingNotesValues(`${TASTING_NOTES_FIELDS.TASTING_NOTES}-${id}`),
      label: t(`${NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES}:${TASTING_NOTES_FIELDS.TASTING_NOTES}`),
      Component: Input,
      textArea: true,
      rows: 3,
      noMargin: true,
      registerParams: { required: true }
    },
    {
      ...getTastingNotesValues(`${TASTING_NOTES_FIELDS.NOSE}-${id}`),
      label: t(`${NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES}:${TASTING_NOTES_FIELDS.NOSE}`),
      Component: Input,
      textArea: true,
      rows: 3,
      noMargin: true,
      registerParams: { required: true }
    },
    {
      ...getTastingNotesValues(`${TASTING_NOTES_FIELDS.PALATE}-${id}`),
      label: t(`${NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES}:${TASTING_NOTES_FIELDS.PALATE}`),
      Component: Input,
      textArea: true,
      rows: 3,
      noMargin: true,
      registerParams: { required: true }
    }
  ];

const createOptions = () => createLanguageOptions().filter(({ id }) => id !== 'en');

const mapInitialTranslation = id => ({
  componentTranslation: removeTranslation =>
    newTranslation(getLanguageById(createOptions(), id), removeTranslation(id), id),
  id
});

export const mapInitialTranslations = languages => languages?.map(id => mapInitialTranslation(id));

export const getTastingNotesFields = ({
  values,
  errors,
  subStepState,
  setSubStepState,
  setValue,
  setError,
  clearErrors
}) => {
  const getTastingNotesValues = getSubStepValues(NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES, values, errors);

  const getCurrentTranslations = () => (subStepState?.translations ? subStepState.translations : []);

  const isLanguageAlreadyInForm = id =>
    !getCurrentTranslations()
      .map(({ id: otherId }) => otherId)
      .includes(id);

  const addTranslation = language => () => {
    const id = getLanguageId(createOptions(), language || '');
    if (language && isLanguageAlreadyInForm(id)) {
      setSubStepState({
        translations: [
          ...getCurrentTranslations(),
          {
            componentTranslation: removeTranslation => newTranslation(language, removeTranslation(id), id),
            id
          }
        ]
      });
      setValue(TASTING_NOTES_FIELDS.LANGUAGE, null);
      clearErrors(TASTING_NOTES_FIELDS.LANGUAGE);
    } else {
      setError(TASTING_NOTES_FIELDS.LANGUAGE, { type: 'custom', message: 'Error' });
    }
  };

  const getSelectorOptions = () =>
    createOptions()
      .filter(({ id }) => isLanguageAlreadyInForm(id))
      .map(({ label }) => label);

  const removeValuesFromFormState = id => {
    setValue(`nose-${id}`, '');
    setValue(`palate-${id}`, '');
    setValue(`tastingNotes-${id}`, '');
  };

  const removeTranslation = id => getCurrentTranslationsToFilter => {
    removeValuesFromFormState(id);
    const newTranslations = getCurrentTranslationsToFilter().filter(({ id: otherId }) => otherId !== id);
    setSubStepState({ translations: newTranslations });
  };

  return [
    {
      key: 'title-en',
      Component: AdelphiFieldsRow,
      centered: true,
      fields: [
        {
          // eslint-disable-next-line react/no-multi-comp
          Component: () => (
            <div className={cn(styles.subStepTitle)}>
              {t(`${NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES}:tastingNotesSubtitle`, { language: 'English' })}
            </div>
          )
        }
      ]
    },
    {
      ...getTastingNotesValues(`${TASTING_NOTES_FIELDS.TASTING_NOTES}-en`),
      label: t(`${NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES}:${TASTING_NOTES_FIELDS.TASTING_NOTES}`),
      Component: Input,
      textArea: true,
      rows: 3,
      noMargin: true,
      registerParams: { required: true }
    },
    {
      ...getTastingNotesValues(`${TASTING_NOTES_FIELDS.NOSE}-en`),
      label: t(`${NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES}:${TASTING_NOTES_FIELDS.NOSE}`),
      Component: Input,
      textArea: true,
      rows: 3,
      noMargin: true,
      registerParams: { required: true }
    },
    {
      ...getTastingNotesValues(`${TASTING_NOTES_FIELDS.PALATE}-en`),
      label: t(`${NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES}:${TASTING_NOTES_FIELDS.PALATE}`),
      Component: Input,
      textArea: true,
      rows: 3,
      noMargin: true,
      registerParams: { required: true }
    },
    ...getCurrentTranslations()?.flatMap(({ componentTranslation }) =>
      componentTranslation(removeTranslation)(getTastingNotesValues, getCurrentTranslations)
    ),
    {
      key: 'languageProp',
      Component: AdelphiFieldsRow,
      fields: [
        getSelectField({
          field: TASTING_NOTES_FIELDS.LANGUAGE,
          substep: NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES,
          values,
          options: getSelectorOptions(),
          customOptions: true,
          getValues: getTastingNotesValues
        }),
        {
          key: 'addTranslation',
          Component: Button,
          children: t(`${NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES}:addTranslationButton`),
          onClick: addTranslation(values?.language),
          secondary: true
        }
      ]
    }
  ];
};
