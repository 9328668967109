import { getSubStepValues } from '#models/general/steps';
import Input from '#components/general/Input';
import { MEASURE_TYPES } from '#components/general/Input/components/Measure/constants';

import { MASHING_FIELDS, NEW_PRODUCT_SUBSTEPS_IDS } from './constants';

export const getMashingFields = ({ values, errors }) => {
  const getMashingValues = getSubStepValues(NEW_PRODUCT_SUBSTEPS_IDS.MASHING, values, errors);
  return [
    {
      ...getMashingValues(MASHING_FIELDS.MASH_OPERATOR),
      Component: Input,
      noMargin: true
    },
    {
      ...getMashingValues(MASHING_FIELDS.FIRST_TEMPERATURE),
      Component: Input,
      measure: MEASURE_TYPES.TEMPERATURE,
      noMargin: true
    },
    {
      ...getMashingValues(MASHING_FIELDS.SECOND_TEMPERATURE),
      Component: Input,
      measure: MEASURE_TYPES.TEMPERATURE,
      noMargin: true
    },
    {
      ...getMashingValues(MASHING_FIELDS.THIRD_TEMPERATURE),
      Component: Input,
      measure: MEASURE_TYPES.TEMPERATURE,
      noMargin: true
    },
    {
      ...getMashingValues(MASHING_FIELDS.GRAVITY),
      Component: Input,
      noMargin: true
    }
  ];
};
