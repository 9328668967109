export const MEASURE_TYPES = {
  TEMPERATURE: 'temperature',
  LENGTH: 'length',
  PERCENTAGE: 'percentage',
  LITRES: 'litres',
  TONS: 'tons',
  KILOGRAMS: 'kilograms',
  FLOWRATE: 'flowrate',
  CUBIC_METER: 'cubicMeter',
  LPM: 'lpm'
};

export const MEASURES = {
  [MEASURE_TYPES.TEMPERATURE]: '°C',
  [MEASURE_TYPES.LENGTH]: 'cm',
  [MEASURE_TYPES.PERCENTAGE]: '%',
  [MEASURE_TYPES.LITRES]: 'L',
  [MEASURE_TYPES.TONS]: 'tons',
  [MEASURE_TYPES.KILOGRAMS]: 'kg',
  [MEASURE_TYPES.FLOWRATE]: 'L/hr',
  [MEASURE_TYPES.CUBIC_METER]: 'm3',
  [MEASURE_TYPES.LPM]: 'LPM'
};
