import { getSubStepValues } from '#models/general/steps';
import Input from '#components/general/Input';
import { FROM_0_TO_99_REGEX } from '#utils/string/regex';

import { FERMENTATION_FIELDS, NEW_PRODUCT_SUBSTEPS_IDS } from './constants';

export const getFermentationFields = ({ values, errors }) => {
  const getFermentationValues = getSubStepValues(NEW_PRODUCT_SUBSTEPS_IDS.FERMENTATION, values, errors);
  return [
    {
      ...getFermentationValues(FERMENTATION_FIELDS.FERMENTATION_OPERATOR),
      Component: Input,
      noMargin: true
    },
    {
      ...getFermentationValues(FERMENTATION_FIELDS.AVERAGE_HOURS),
      Component: Input,
      needsRegex: true,
      customRegex: FROM_0_TO_99_REGEX,
      noMargin: true
    },
    {
      ...getFermentationValues(FERMENTATION_FIELDS.TYPE_OF_YEAST),
      Component: Input,
      noMargin: true
    }
  ];
};
