import React from 'react';
import cn from 'classnames';
import { bool, string } from 'prop-types';

import styles from '../../styles.module.scss';

import { isValidFalsyValue, withElevatedType } from './utils';

function Placeholder({ className, focused, value, textArea, placeholder, type }) {
  return (
    <span
      className={cn(styles.placeholder, className, {
        [styles.defaultTop]: !className,
        [styles.focused]: focused,
        [styles.withValue]: focused || !!value || withElevatedType(type) || isValidFalsyValue(value),
        [styles.area]: textArea
      })}>
      {placeholder}
    </span>
  );
}

Placeholder.propTypes = {
  className: string,
  focused: bool,
  placeholder: string,
  textArea: bool,
  type: string,
  value: bool
};

export default Placeholder;
