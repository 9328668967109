import i18next from 'i18next';

i18next.addResources('en', 'BottleMainInformation', {
  yourBottle: 'Your bottle',
  bottleTitle: 'of {{- title}}'
});

i18next.addResources('fr', 'BottleMainInformation', {
  yourBottle: 'Votre bouteille',
  bottleTitle: 'sur {{- title}}'
});

i18next.addResources('it', 'BottleMainInformation', {
  yourBottle: 'La tua bottiglia',
  bottleTitle: 'Di {{- title}}'
});

i18next.addResources('de', 'BottleMainInformation', {
  yourBottle: 'Ihre Flasche',
  bottleTitle: 'von {{- title}}'
});

i18next.addResources('zh', 'BottleMainInformation', {
  yourBottle: '瓶号',
  bottleTitle: '{{- title}}'
});

i18next.addResources('zt', 'BottleMainInformation', {
  yourBottle: '瓶號',
  bottleTitle: '{{- title}}'
});
