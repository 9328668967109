import { getSubStepValues } from '#models/general/steps';
import { getSelectField } from '#utils/select';

import { NEW_PRODUCT_SUBSTEPS_IDS, SELECT_TEMPLATE_FIELDS } from './constants';

export const TEMPLATES_OPTIONS = {
  SINGLE_MALT: 'singleMalt',
  LIMITED_RELEASE: 'limitedRelease',
  SINGLE_CASK: 'singleCask'
};

export const getSelectTemplateFields = ({ values, errors }) => {
  const getSelectTemplateValues = getSubStepValues(NEW_PRODUCT_SUBSTEPS_IDS.SELECT_TEMPLATE, values, errors);
  return [
    getSelectField({
      field: SELECT_TEMPLATE_FIELDS.TEMPLATE_TYPE,
      substep: NEW_PRODUCT_SUBSTEPS_IDS.SELECT_TEMPLATE,
      values,
      options: TEMPLATES_OPTIONS,
      getValues: getSelectTemplateValues
    })
  ];
};
