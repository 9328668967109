import { completeTypes, createTypes, withPostSuccess } from 'redux-recompose';
import { push } from 'connected-react-router';

import { removeAuthHeader, setAuthHeader } from '#config/api';
import * as AuthService from '#services/AuthService';
import LocalStorage from '#services/LocalStorageService';
import { ROUTES } from '#constants/routes';

export const actions = createTypes(
  completeTypes({ primaryActions: ['SIGN_IN', 'GET_CURRENT_USER', 'LOG_OUT'] }),
  '@@AUTH'
);

const targets = {
  SIGN_IN: 'signIn',
  CURRENT_USER: 'currentUser'
};

export const actionCreators = {
  init: () => dispatch => {
    const token = LocalStorage.getTokenManager();
    if (token && token.token) {
      setAuthHeader(token.token);
      dispatch(actionCreators.getCurrentUser());
    }
  },
  getCurrentUser: () => ({
    type: actions.GET_CURRENT_USER,
    target: targets.CURRENT_USER,
    service: AuthService.getCurrentUser,
    injections: [
      withPostSuccess((_, response) => {
        const tokenManager = LocalStorage.getTokenManager();
        LocalStorage.setTokenManager({
          ...tokenManager,
          role: response?.data?.role
        });
      })
    ]
  }),
  signIn: ({ user }) => ({
    type: actions.SIGN_IN,
    target: targets.SIGN_IN,
    payload: user,
    service: AuthService.signIn,
    injections: [
      withPostSuccess((dispatch, response) => {
        const token = response?.data?.token;
        LocalStorage.setTokenManager(response.data);
        setAuthHeader(token);
        dispatch(push(ROUTES.ADELPHI_MAIN_MENU.path));
      })
    ]
  }),
  logout: oAuthLogoutCallback => dispatch => {
    removeAuthHeader();
    LocalStorage.removeTokenManager();
    dispatch({
      type: actions.LOG_OUT,
      target: targets.CURRENT_USER
    });
    oAuthLogoutCallback?.();
    if (!oAuthLogoutCallback) {
      dispatch(push(ROUTES.HOME.path));
    }
  }
};

export default actionCreators;
