import i18next from 'i18next';

i18next.addResources('en', 'PlayButton', {
  play: 'Play'
});

i18next.addResources('fr', 'PlayButton', {
  play: 'Venez Jouer!'
});

i18next.addResources('it', 'PlayButton', {
  play: 'È l’ora di divertirsi!'
});

i18next.addResources('de', 'PlayButton', {
  play: 'Spielen'
});

i18next.addResources('zh', 'PlayButton', {
  play: '播放（发音）'
});

i18next.addResources('zt', 'PlayButton', {
  play: '播放（發音）'
});
