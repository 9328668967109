import { string } from 'prop-types';

import { isDate, isDateTimeLocal } from '../../utils';

import { formatDateToDDMMYYYY, formatDateLocalToDDMMYYYY } from '#utils/date';

import styles from './styles.module.scss';
import { PLACEHOLDERS } from './constants';
import { getDateValueOrPlaceholder } from './utils';

function DateFormat({ type, inputValue }) {
  const date = isDate(type);
  const dateTimeLocal = isDateTimeLocal(type);
  return date || dateTimeLocal ? (
    <span className={styles.dateTime}>
      {getDateValueOrPlaceholder({
        isType: date || dateTimeLocal,
        placeholder: date ? PLACEHOLDERS.DATE : PLACEHOLDERS.DATE_LOCAL,
        format: date ? formatDateToDDMMYYYY : formatDateLocalToDDMMYYYY,
        inputValue
      })}
    </span>
  ) : null;
}

DateFormat.propTypes = {
  inputValue: string,
  type: string
};

export default DateFormat;
