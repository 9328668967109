import { t } from 'i18next';
import { func, shape, string } from 'prop-types';

import { isImage } from '#utils/file';

import styles from './styles.module.scss';
import { getFileName } from './utils';

function FileInputLayout({
  placeholder,
  register,
  name,
  registerParams,
  accept,
  srcUrl,
  fileType = 'image'
}) {
  return (
    <div className={styles.container}>
      {placeholder && <span className={styles.titleLabel}>{placeholder}</span>}
      <input type="file" className={styles.fileInput} {...register(name, registerParams)} accept={accept} />
      {srcUrl && (
        <div className={styles.filePreviewContainer}>
          <span className={styles.fileTitle}>{t('FileInput:previewTitle')}</span>
          <div className={styles.fileContainer}>
            {isImage(fileType) ? (
              <div className={styles.imageContainer}>
                <img className={styles.image} src={srcUrl} alt="preview" />
              </div>
            ) : (
              <span>{getFileName(srcUrl)}</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

FileInputLayout.propTypes = {
  accept: string,
  fileType: string,
  handleChange: func,
  name: string,
  placeholder: string,
  register: func,
  registerParams: shape({}),
  srcUrl: string
};

export default FileInputLayout;
