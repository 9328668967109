import i18next from 'i18next';

i18next.addResources('en', 'ProductsTable', {
  productName: 'Product',
  productDate: 'Date',
  templateType: 'Template',
  unitsToBeCreated: 'Units to be created',
  totalCirculatingBottles: 'Circulating bottles',
  edit: 'Edit',
  preview: 'Preview',
  download: 'Download'
});
