import { string, shape, func, arrayOf } from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';
import Placeholder from './components/Placeholder';
import { getPlaceholderOption } from './utils';

const Select = ({
  name,
  placeholder,
  options,
  defaultValue,
  error,
  className,
  selectClassName,
  optionClassName,
  register,
  registerParams,
  setValue,
  value: selectedValue,
  ...selectProps
}) => {
  const handleChange = event => {
    setValue?.(name, event.target.value);
  };
  const placeholderOption = getPlaceholderOption(placeholder);
  return (
    <div className={cn(styles.container, className)}>
      <select
        name={name}
        className={cn(styles.select, { [styles.selectError]: !selectedValue && error }, selectClassName)}
        defaultValue={defaultValue}
        {...register(name, registerParams)}
        {...selectProps}
        onChange={handleChange}>
        <Placeholder label={placeholder} optionClassName={cn(styles.option, optionClassName)} />
        {[placeholderOption, ...options].map(({ label, value, ...optionProps }) => (
          <option key={value} value={value} className={cn(styles.option, optionClassName)} {...optionProps}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  options: arrayOf(shape({ label: string, value: string })).isRequired,
  className: string,
  defaultValue: string,
  error: string,
  errorClassName: string,
  handleChange: func,
  name: string,
  optionClassName: string,
  placeholder: string,
  register: func,
  registerParams: shape({}),
  selectClassName: string,
  setValue: func,
  value: string
};

Select.defaultProps = {
  className: '',
  error: '',
  errorClassName: '',
  name: '',
  optionClassName: '',
  placeholder: '',
  selectClassName: ''
};

export default Select;
