import { getSubStepValues } from '#models/general/steps';
import Input from '#components/general/Input';

import { BARLEY_FIELDS, NEW_PRODUCT_SUBSTEPS_IDS } from './constants';

export const getBarleyFields = ({ values, errors }) => {
  const getBarleyValues = getSubStepValues(NEW_PRODUCT_SUBSTEPS_IDS.BARLEY, values, errors);
  return [
    {
      ...getBarleyValues(BARLEY_FIELDS.BARLEY_VARIETY),
      Component: Input,
      noMargin: true
    },
    {
      ...getBarleyValues(BARLEY_FIELDS.FARM_NAME),
      Component: Input,
      noMargin: true
    },
    {
      ...getBarleyValues(BARLEY_FIELDS.FIELD_NAME),
      Component: Input,
      noMargin: true
    },
    {
      ...getBarleyValues(BARLEY_FIELDS.MALTED_BY),
      Component: Input,
      noMargin: true
    }
  ];
};
