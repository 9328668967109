import { takeNCharacters } from '@Zerf-dev/utils';

export const formatDateToDDMMYYYY = date => {
  if (!date) {
    return '';
  }
  const [year, month, day] = date.split('-');
  const result = [day, month, year].join('/');
  return result;
};

export const formatDateLocalToDDMMYYYY = date => {
  if (!date) {
    return '';
  }
  const [completeDate, time] = date.split('T');
  return `${formatDateToDDMMYYYY(completeDate)} ${time}`;
};

export const getTimeBetweenDates = (first, second) => {
  const firstDate = new Date(first);
  const secondDate = new Date(second);
  const milliseconds = secondDate - firstDate;

  const seconds = Math.floor(Math.abs(milliseconds) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return { days, hours: hours % 24, minutes: minutes % 60 };
};

export const formatTimeBetweenDates = (firstDate, secondDate) => {
  const { days, hours, minutes } = getTimeBetweenDates(firstDate, secondDate);
  return days && hours && minutes ? `${days} days, ${hours} hours, ${minutes} minutes` : '';
};

export const getDateToFormatFromISOFormat = date => date?.split('T')[0];

export const formatDateToDDMMYYYYFromISO = date => formatDateToDDMMYYYY(getDateToFormatFromISOFormat(date));

export const getCurrentDateOnISOFormat = () => getDateToFormatFromISOFormat(new Date().toISOString());

// eslint-disable-next-line id-length
export const formatDateLocalToDDMMYYYYWithoutTime = date => {
  if (!date) {
    return '';
  }
  const [completeDate] = date.split('T');
  return formatDateToDDMMYYYY(completeDate);
};

export const formatDateWithTimeToDDMMYYYY = date => {
  const takeDateWithoutTime = takeNCharacters(10);
  return date ? formatDateToDDMMYYYY(takeDateWithoutTime(date)) : '';
};
