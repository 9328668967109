import { bool, func, number, shape, string } from 'prop-types';
import cn from 'classnames';

import Placeholder from './components/Placeholder';
import styles from './styles.module.scss';
import { useInput } from './hooks';
import Measure from './components/Measure';
import { isDateTimeLocal, isDate } from './utils';
import DateFormat from './components/DateFormat';

function Input({
  name,
  handleChange,
  error,
  format,
  register,
  placeholder,
  setValue,
  containerClassName,
  value,
  validatesKeyPress,
  shouldTrim,
  placeholderClassName,
  textArea,
  staticPlaceholder,
  formState,
  rows = 5,
  maxLength,
  type,
  ...inputProps
}) {
  const {
    Component,
    handleOnBlurInput,
    onInputChange,
    focus,
    focused,
    handleOnFocus,
    inputValue,
    restRegistration
  } = useInput({
    formState,
    name,
    textArea,
    value,
    register,
    validatesKeyPress,
    error,
    handleChange,
    shouldTrim,
    setValue,
    maxLength,
    type,
    ...inputProps
  });
  return (
    <div
      className={cn(
        styles.inputContent,
        {
          [styles.areaContainer]: textArea,
          [styles.focused]: !!focused,
          [styles.inputContentError]: !!error
        },
        containerClassName
      )}
      onClick={focus}>
      {!!placeholder && !staticPlaceholder && (
        <Placeholder
          className={placeholderClassName}
          focused={!!focused}
          value={inputValue}
          textArea={!!textArea}
          placeholder={placeholder}
          type={type}
          {...inputProps}
        />
      )}
      <div className={styles.contentContainer}>
        <DateFormat type={type} inputValue={inputValue} />
        <Component
          className={cn({
            [styles.input]: !textArea,
            [styles.area]: !!textArea,
            [styles.date]: isDate(type) || isDateTimeLocal(type)
          })}
          id={name}
          placeholder={staticPlaceholder && placeholder}
          name={name}
          onFocus={handleOnFocus}
          onBlur={handleOnBlurInput}
          value={!focused && !!format ? format(inputValue) : inputValue}
          rows={rows}
          {...restRegistration}
          onChange={onInputChange}
          type={type}
          {...inputProps}
        />
        <Measure {...inputProps} />
      </div>
    </div>
  );
}

Input.propTypes = {
  name: string.isRequired,
  containerClassName: string,
  error: string,
  format: func,
  formState: shape,
  handleChange: func,
  maxLength: number,
  placeholder: string,
  placeholderClassName: string,
  register: shape,
  rows: number,
  setValue: func,
  shouldTrim: bool,
  staticPlaceholder: bool,
  textArea: bool,
  type: string,
  validatesKeyPress: string,
  value: string
};

Input.defaultProps = {
  register: {
    onBlur: () => undefined,
    onChange: () => undefined
  },
  shouldTrim: true,
  staticPlaceholder: false,
  textArea: false
};

export default Input;
