import { create } from 'apisauce';

import LocalStorage from '#services/LocalStorageService';

export const createApiWithURL = baseURL =>
  create({
    baseURL,
    timeout: 15000
  });

export const HEADERS = {
  AUTHORIZATION: 'Authorization',
  X_TENANT: 'x-tenant'
};

const RESPONSE_STATUS_CODES = {
  UNAUTHORIZED: 401
}

const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  headers: { [HEADERS.X_TENANT]: process.env.REACT_APP_TENANT }
});

export const setAuthHeader = token => api.setHeader(HEADERS.AUTHORIZATION, `Bearer ${token}`);

export const removeAuthHeader = () => api.deleteHeader(HEADERS.AUTHORIZATION);

export const apiSetup = (baseApi, logout) => {
  baseApi.addResponseTransform(response => {
    if (response.status === RESPONSE_STATUS_CODES.UNAUTHORIZED && !!LocalStorage.getTokenManager()) {
      logout?.();
    }
  });
};

export default api;
