import { getSubStepValues } from '#models/general/steps';
import Input from '#components/general/Input';
import { isValidEmail, isValidWebsite } from '#utils/validations';

import { IMPORTER_FIELDS, NEW_PRODUCT_SUBSTEPS_IDS } from './constants';

export const getImporterFields = ({ values, errors }) => {
  const getImporterValues = getSubStepValues(NEW_PRODUCT_SUBSTEPS_IDS.IMPORTER, values, errors);
  return [
    {
      ...getImporterValues(IMPORTER_FIELDS.IMPORTERS_NAME),
      Component: Input,
      noMargin: true
    },
    {
      ...getImporterValues(IMPORTER_FIELDS.WEBSITE),
      Component: Input,
      registerParams: { validate: isValidWebsite },
      noMargin: true
    },
    {
      ...getImporterValues(IMPORTER_FIELDS.EMAIL_ADDRESS),
      Component: Input,
      registerParams: { validate: isValidEmail },
      noMargin: true
    }
  ];
};
