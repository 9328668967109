import FileActions from '#redux/File/actions';
import ProductsActions from '#redux/Products/actions';

const actionWithFile = action => (files, values, urlTarget) => dispatch => {
  const productAction = url => () => {
    dispatch(ProductsActions[action]?.({ ...values, [urlTarget]: url }));
  };
  dispatch(FileActions.getSignedUrl({ file: files[0], postUploadSuccessAction: productAction }));
};

export const createProductWithFile = actionWithFile('createProduct');

export const editProductWithFile = actionWithFile('editProduct');

export const modProductImageAndMaturation =
  action =>
  ({ productPicture, maturationFile, ...values }) =>
  dispatch => {
    const actionProduct = productPictureURL => maturationFileURL => () => {
      dispatch(ProductsActions[action]?.({ ...values, productPictureURL, maturationFileURL }));
    };
    const uploadMaturationFile = productPictureURL => () => {
      dispatch(
        FileActions.getSignedUrl({
          file: maturationFile[0],
          postUploadSuccessAction: actionProduct(productPictureURL)
        })
      );
    };
    dispatch(
      FileActions.getSignedUrl({ file: productPicture[0], postUploadSuccessAction: uploadMaturationFile })
    );
  };
