import { t } from 'i18next';
import cn from 'classnames';

import Select from '#components/general/Select';
import Input from '#components/general/Input';
import AdelphiFieldsRow from '#components/business/AdelphiFieldsRow';

import styles from './styles.module.scss';

const optionLabelByTarget = (options, target, option) => (target ? t(`${target}:${option}`) : options);

export const mapSelectOptions = (options, target, customOptions) =>
  Object.values(options).map(option => ({
    label: customOptions ? option : optionLabelByTarget(options, target, option),
    value: option
  }));

export const needsManualInputByOption = option => option === 'manual';

export const getSelectField = ({
  field,
  substep,
  manualField,
  values,
  options,
  getValues,
  fieldRowClassName,
  innerClassName = styles.selectRowInnerContainer,
  needsManualInput = values?.[field] === 'manual',
  selectClassName,
  customOptions = false
}) => {
  const selectField = {
    ...getValues(field),
    Component: Select,
    innerClassName,
    innerFieldContainerClassName: styles.selectColumn,
    options: mapSelectOptions(options, substep, customOptions),
    className: cn({ [styles.selectWithoutManual]: !needsManualInput }, selectClassName)
  };
  return needsManualInput
    ? {
        key: field,
        Component: AdelphiFieldsRow,
        innerClassName,
        fieldRowClassName: cn(styles.fieldRowWithManual, fieldRowClassName),
        fields: [
          selectField,
          ...(needsManualInputByOption(values[field])
            ? [
                {
                  ...getValues(manualField),
                  Component: Input
                }
              ]
            : [
                {
                  key: 'blankSpace',
                  Component: () => <div />
                }
              ])
        ]
      }
    : selectField;
};
