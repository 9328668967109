import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { hotjar } from 'react-hotjar';

import store from '#redux/store';
import { useMount } from '#hooks/useMount';
import MixpanelService from '#services/MixpanelService';

import Routes from './components/general/Routes';

const App = () => {
  useMount(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV);
    MixpanelService.init();
  });
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default hot(App);
