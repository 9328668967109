import { getSubStepValues } from '#models/general/steps';
import FileInput from '#components/general/FileInput';
import Input from '#components/general/Input';
import { ACCEPT_ONLY_PDFS } from '#constants/files';

import { MATURATION_FIELDS, NEW_PRODUCT_SUBSTEPS_IDS } from './constants';

export const getMaturationFields = ({ values, errors }) => {
  const getMaturationValues = getSubStepValues(NEW_PRODUCT_SUBSTEPS_IDS.MATURATION, values, errors);
  return [
    {
      ...getMaturationValues(MATURATION_FIELDS.CASK_TYPE),
      Component: Input,
      noMargin: true
    },
    {
      ...getMaturationValues(MATURATION_FIELDS.WOOD_FILL),
      Component: Input,
      noMargin: true
    },
    {
      ...getMaturationValues(MATURATION_FIELDS.SPIRIT_TYPE),
      Component: Input,
      noMargin: true
    },
    {
      ...getMaturationValues(MATURATION_FIELDS.VINTAGE),
      Component: Input,
      noMargin: true
    },
    {
      ...getMaturationValues(MATURATION_FIELDS.CASK_NO),
      Component: Input,
      noMargin: true
    },
    {
      ...getMaturationValues(MATURATION_FIELDS.MATURATION_FILE),
      Component: FileInput,
      accept: ACCEPT_ONLY_PDFS,
      noMargin: true,
      srcUrl: values?.maturationFileURL,
      fileType: 'pdf'
    }
  ];
};
