import i18next from 'i18next';

i18next.addResources('en', 'TastingNotes', {
  tasting: 'Our tasting\nnotes',
  tasteHistory: 'Ardnamurchan distillery\ntaste the story',
  tastingNotesTitle: 'Tasting notes',
  noseTitle: 'Nose',
  palateTitle: 'Palate'
});

i18next.addResources('fr', 'TastingNotes', {
  tasting: 'Nos notes\nde degustation',
  tasteHistory: 'Ardnamurchan Distillery\n‘Taste the Story’ (lit: ‘Goutez l’histoire’)',
  tastingNotesTitle: 'Notes de degustation:',
  noseTitle: 'Nez:',
  palateTitle: 'En bouche:'
});

i18next.addResources('it', 'TastingNotes', {
  tasting: 'Le nostre note\ndi degustazione',
  tasteHistory: 'Distilleria Ardnamurchan\nTaste the Story',
  tastingNotesTitle: 'Note di degustazione',
  noseTitle: 'Naso',
  palateTitle: 'Palato'
});

i18next.addResources('de', 'TastingNotes', {
  tasting: 'Unsere\nVerkostungsnotizen',
  tasteHistory: 'Ardnamurchan-Brennerei\nProbieren Sie die Geschichte',
  tastingNotesTitle: 'Verkostungsnotizen',
  noseTitle: 'Nase',
  palateTitle: 'Gaumen'
});

i18next.addResources('zh', 'TastingNotes', {
  tasting: '我们的品饮笔记',
  tasteHistory: '艾德麦康蒸馏厂\n舌尖上的威士忌故事',
  tastingNotesTitle: '品饮笔记',
  noseTitle: '香氣',
  palateTitle: '品尝'
});

i18next.addResources('zt', 'TastingNotes', {
  tasting: '我們的品飲筆記',
  tasteHistory: '艾德麥康酒廠\n舌尖上的威士忌故事',
  tastingNotesTitle: '品飲筆記',
  noseTitle: '香氣',
  palateTitle: '口感'
});
