import { getSubStepValues } from '#models/general/steps';
import Input from '#components/general/Input';

import { DISTILLATION_FIELDS, NEW_PRODUCT_SUBSTEPS_IDS } from './constants';

export const getDistillationFields = ({ values, errors }) => {
  const getDistillationValues = getSubStepValues(NEW_PRODUCT_SUBSTEPS_IDS.DISTILLATION, values, errors);
  return [
    {
      ...getDistillationValues(DISTILLATION_FIELDS.STILL_OPERATOR),
      Component: Input,
      noMargin: true
    },
    {
      ...getDistillationValues(DISTILLATION_FIELDS.MIN_HYDROMETER),
      Component: Input,
      noMargin: true
    },
    {
      ...getDistillationValues(DISTILLATION_FIELDS.MAX_HYDROMETER),
      Component: Input,
      noMargin: true
    }
  ];
};
