import { useState } from 'react';

export const useFocus = name => {
  const [isFocused, setFocused] = useState(false);
  const focus = () => document.getElementById(name)?.focus();

  const handleOnFocus = () => setFocused(true);
  const handleOnBlur =
    (error = '', onBlur) =>
    event => {
      const newValue = event?.target?.value || '';

      setFocused(false);
      if (newValue || error) {
        onBlur?.(event);
      }
    };

  return { isFocused, focus, handleOnFocus, handleOnBlur };
};
