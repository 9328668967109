import React from 'react';
import { bool, func, string } from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

function AdelphiFieldLayout({ label, className, innerClassName, Component, noMargin, ...props }) {
  return (
    <div
      className={cn(
        styles.adelphiFieldContainer,
        { [styles.adelphiFieldContainerNoMargin]: noMargin },
        className
      )}>
      <div className={cn(styles.innerContainer, innerClassName)}>
        <Component placeholder={label} {...props} />
      </div>
    </div>
  );
}

AdelphiFieldLayout.propTypes = {
  className: string,
  Component: func,
  innerClassName: string,
  label: string,
  labelClassName: string,
  noMargin: bool
};

AdelphiFieldLayout.defaultProps = {
  noMargin: false
};

export default AdelphiFieldLayout;
