import { BOTTLED_BY_OPTIONS } from '#models/business/NewProduct/bottledBy';

const mapBottledBy = params =>
  Object.values(BOTTLED_BY_OPTIONS).reduce(
    (prevValues, actValue) => ({
      ...prevValues,
      ...(params[actValue] ? { [actValue]: parseInt(params[`${actValue}Manual`]) } : {})
    }),
    {}
  );

export const mapCreateProductData = ({
  productName,
  productDate,
  templateType,
  totalCirculatingBottles,
  unitsToBeCreated,
  productPictureURL,
  tastingNotes,
  barleyVariety,
  farmName,
  fieldName,
  maltedBy,
  mashOperator,
  firstTemperature,
  secondTemperature,
  thirdTemperature,
  gravity,
  fermentationOperator,
  averageHours,
  typeOfYeast,
  stillOperator,
  minHydrometer,
  maxHydrometer,
  caskType,
  woodFill,
  spiritType,
  vintage,
  caskNO,
  importersName,
  website,
  emailAddress,
  maturationFileURL,
  nose,
  palate,
  ...props
}) => ({
  productName,
  productDate,
  templateType,
  totalCirculatingBottles,
  unitsToBeCreated,
  productPictureURL,
  bottledBy: mapBottledBy(props),
  tastingNotes,
  barleyVariety,
  farmName,
  fieldName,
  maltedBy,
  mashOperator,
  firstTemperature,
  secondTemperature,
  thirdTemperature,
  gravity,
  fermentationOperator,
  averageHours,
  typeOfYeast,
  stillOperator,
  minHydrometer,
  maxHydrometer,
  caskType,
  woodFill,
  spiritType,
  vintage,
  caskNO,
  importersName,
  website,
  emailAddress,
  maturationFileURL,
  nose,
  palate
});

export const mapEditProductData = ({
  id,
  productName,
  productDate,
  templateType,
  totalCirculatingBottles,
  unitsToBeCreated,
  productPictureURL,
  tastingNotes,
  barleyVariety,
  farmName,
  fieldName,
  maltedBy,
  mashOperator,
  firstTemperature,
  secondTemperature,
  thirdTemperature,
  gravity,
  fermentationOperator,
  averageHours,
  typeOfYeast,
  stillOperator,
  minHydrometer,
  maxHydrometer,
  caskType,
  woodFill,
  spiritType,
  vintage,
  caskNO,
  importersName,
  website,
  emailAddress,
  maturationFileURL,
  nose,
  palate,
  ...props
}) => ({
  id,
  productName,
  productDate,
  templateType,
  totalCirculatingBottles,
  unitsToBeCreated,
  productPictureURL,
  bottledBy: mapBottledBy(props),
  tastingNotes,
  barleyVariety,
  farmName,
  fieldName,
  maltedBy,
  mashOperator,
  firstTemperature,
  secondTemperature,
  thirdTemperature,
  gravity,
  fermentationOperator,
  averageHours,
  typeOfYeast,
  stillOperator,
  minHydrometer,
  maxHydrometer,
  caskType,
  woodFill,
  spiritType,
  vintage,
  caskNO,
  importersName,
  website,
  emailAddress,
  maturationFileURL,
  nose,
  palate
});
