import i18next from 'i18next';

i18next.addResources('en', 'AdelphiStatementFinalCalculationsModal', {
  finalCalculationsTitle: 'Final Calculations',
  grossLitresAlcoholProduced: 'Gross Litres Alcohol Produced',
  deductFeintsLastPeriod: 'Deduct Feints Last Period',
  netLitresAlcoholProduced: 'Net Litres Alcohol Produced',
  lalcProducedToLastPeriod: 'Lts/Alc Produced to Last Period',
  lalcProducedToDate: 'Lts/Alc Produced to Date',
  lalcWarehousedThisWeek: 'Lts/Alc Warehoused This Week',
  lalcWarehousedToLastWeek: 'Lts/Alc Warehoused to Last Week',
  lalcWarehousedToDate: 'Lts/Alc Warehoused to Date',
  predictedAlcoholAttained: 'Predicted Alcohol Attained',
  percentageOverAttenuation: 'Percentage Over Attenuation',
  mashTunExtract: 'Mash Tun Extract',
  tonesOfMaltUsed: 'Tones of Malt Used',
  yieldLalcPerTonne: 'Yield Lts/Alc per Tonne',
  dependingFeints: 'Depending Feints',
  srwvRemanent: 'SRWV Remanent'
});
