import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import * as FileService from '../../services/FileService';

import { TARGETS } from './constants';
import { mapFileUrl } from './mappers';

export const actions = createTypes(
  completeTypes({ primaryActions: ['UPLOAD_FILE', 'GET_SIGNED_URL'], ignoredActions: [] }),
  '@@FILE'
);

export const actionCreators = {
  getSignedUrl: ({ file, postUploadSuccessAction }) => ({
    type: actions.GET_SIGNED_URL,
    target: TARGETS.SIGNED_URL,
    payload: file,
    service: FileService.getSignedUrl,
    injections: [
      withPostSuccess((dispatch, response) => {
        dispatch(
          actionCreators.uploadFile({
            file,
            signedUrlData: response.data,
            postSuccessAction: postUploadSuccessAction?.(mapFileUrl(response.data))
          })
        );
      })
    ]
  }),
  uploadFile: ({ file, signedUrlData, postSuccessAction }) => ({
    type: actions.UPLOAD_FILE,
    target: TARGETS.UPLOAD_FILE,
    payload: { file, signedUrlData },
    service: FileService.uploadFileToS3,
    injections: [
      withPostSuccess(() => {
        postSuccessAction?.();
      })
    ]
  })
};

export default actionCreators;
