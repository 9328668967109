/* eslint-disable react/forbid-prop-types */
import { string, number, bool, oneOf, shape } from 'prop-types';
import cn from 'classnames';
import Lottie from 'lottie-react';

import { getOptions, SPINNER_TYPES } from './constants';
import styles from './styles.module.scss';

function Loading({ className, lottieStyle, type, loop, autoplay }) {
  return (
    <div className={cn(styles.spinnerContainer, className)}>
      <Lottie style={lottieStyle} {...getOptions({ type, loop, autoplay })} />
    </div>
  );
}

Loading.propTypes = {
  autoplay: bool,
  className: string,
  height: number,
  loop: bool,
  lottieStyle: shape({
    height: string,
    width: string,
    margin: string
  }),
  type: oneOf(Object.keys(SPINNER_TYPES)),
  width: number
};

Loading.defaultProps = {
  lottieStyle: {
    height: '80px',
    width: '80px',
    margin: 'auto'
  }
};

export default Loading;
