import { lazy } from 'react';

/* eslint-disable id-length */

import { ROLES } from './users';

const Home = lazy(() => import('#screens/Home'));
const ErrorScreen = lazy(() => import('#screens/ErrorScreen'));
const Bottle = lazy(() => import('#screens/Bottle'));

export const ROUTES = {
  HOME: {
    path: '/',
    basePath: '/',
    exact: true,
    publicRoute: true,
    supportedRoles: [ROLES.USER, ROLES.ADMIN],
    component: Home
  },
  ERROR: {
    path: '/error',
    basePath: '/error',
    publicRoute: true,
    supportedRoles: [ROLES.USER, ROLES.ADMIN],
    exact: true,
    component: ErrorScreen
  },
  BOTTLE: {
    path: '/products/:productId/:bottleNumber',
    basePath: '/products',
    publicRoute: true,
    supportedRoles: [ROLES.USER, ROLES.ADMIN],
    exact: true,
    component: Bottle
  },
  LEGACY_BOTTLES: {
    path: '/newBottles-detail/:bottleId',
    basePath: '/newBottles-detail',
    publicRoute: true,
    supportedRoles: [ROLES.USER, ROLES.ADMIN],
    exact: true,
    component: Bottle
  }
};

export const BASE_PATHS = [ROUTES.ERROR.basePath, ROUTES.BOTTLE.basePath, ROUTES.LEGACY_BOTTLES.basePath];

export const DEFAULT_ROUTE_PATH = {
  [ROLES.USER]: ROUTES.HOME.path,
  [ROLES.ADMIN]: ROUTES.HOME.path
};
