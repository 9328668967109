import { getSubSteps } from '#models/general/steps';

import { getBarleyFields } from './barley';
import { getBottledByFields } from './bottledBy';
import { getDistillationFields } from './distillation';
import { getFermentationFields } from './fermentation';
import { getImporterFields } from './importer';
import { getMashingFields } from './mashing';
import { getMaturationFields } from './maturation';
import { getProductInformationFields } from './productInformation';
import { getSelectTemplateFields } from './selectTemplate';
import { getTastingNotesFields } from './tastingNotes';
import styles from './styles.module.scss';

export const NEW_PRODUCT_TARGET = 'NewProductModal';

export const NEW_PRODUCT_SUBSTEPS_IDS = {
  SELECT_TEMPLATE: 'selectTemplate',
  PRODUCT_INFORMATION: 'productInformation',
  BOTTLED_BY: 'bottledBy',
  TASTING_NOTES: 'tastingNotes',
  BARLEY: 'barley',
  MASHING: 'mashing',
  FERMENTATION: 'fermentation',
  DISTILLATION: 'distillation',
  MATURATION: 'maturation',
  IMPORTER: 'importer'
};

const NEW_PRODUCT_NEXT_SUBSTEPS = {
  [NEW_PRODUCT_SUBSTEPS_IDS.SELECT_TEMPLATE]: NEW_PRODUCT_SUBSTEPS_IDS.PRODUCT_INFORMATION,
  [NEW_PRODUCT_SUBSTEPS_IDS.PRODUCT_INFORMATION]: NEW_PRODUCT_SUBSTEPS_IDS.BOTTLED_BY,
  [NEW_PRODUCT_SUBSTEPS_IDS.BOTTLED_BY]: NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES,
  [NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES]: NEW_PRODUCT_SUBSTEPS_IDS.BARLEY,
  [NEW_PRODUCT_SUBSTEPS_IDS.BARLEY]: NEW_PRODUCT_SUBSTEPS_IDS.MASHING,
  [NEW_PRODUCT_SUBSTEPS_IDS.MASHING]: NEW_PRODUCT_SUBSTEPS_IDS.FERMENTATION,
  [NEW_PRODUCT_SUBSTEPS_IDS.FERMENTATION]: NEW_PRODUCT_SUBSTEPS_IDS.DISTILLATION,
  [NEW_PRODUCT_SUBSTEPS_IDS.DISTILLATION]: NEW_PRODUCT_SUBSTEPS_IDS.MATURATION,
  [NEW_PRODUCT_SUBSTEPS_IDS.MATURATION]: NEW_PRODUCT_SUBSTEPS_IDS.IMPORTER
};

const NEW_PRODUCT_PREVIOUS_SUBSTEPS = {
  [NEW_PRODUCT_SUBSTEPS_IDS.PRODUCT_INFORMATION]: NEW_PRODUCT_SUBSTEPS_IDS.SELECT_TEMPLATE,
  [NEW_PRODUCT_SUBSTEPS_IDS.BOTTLED_BY]: NEW_PRODUCT_SUBSTEPS_IDS.PRODUCT_INFORMATION,
  [NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES]: NEW_PRODUCT_SUBSTEPS_IDS.BOTTLED_BY,
  [NEW_PRODUCT_SUBSTEPS_IDS.BARLEY]: NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES,
  [NEW_PRODUCT_SUBSTEPS_IDS.MASHING]: NEW_PRODUCT_SUBSTEPS_IDS.BARLEY,
  [NEW_PRODUCT_SUBSTEPS_IDS.FERMENTATION]: NEW_PRODUCT_SUBSTEPS_IDS.MASHING,
  [NEW_PRODUCT_SUBSTEPS_IDS.DISTILLATION]: NEW_PRODUCT_SUBSTEPS_IDS.FERMENTATION,
  [NEW_PRODUCT_SUBSTEPS_IDS.MATURATION]: NEW_PRODUCT_SUBSTEPS_IDS.DISTILLATION,
  [NEW_PRODUCT_SUBSTEPS_IDS.IMPORTER]: NEW_PRODUCT_SUBSTEPS_IDS.MATURATION
};

export const NEW_PRODUCT_SUBSTEPS = getSubSteps(
  NEW_PRODUCT_TARGET,
  NEW_PRODUCT_SUBSTEPS_IDS,
  NEW_PRODUCT_NEXT_SUBSTEPS,
  NEW_PRODUCT_PREVIOUS_SUBSTEPS
);

export const NEW_PRODUCT_COMPLETE_FIELDS = {
  [NEW_PRODUCT_SUBSTEPS_IDS.SELECT_TEMPLATE]: getSelectTemplateFields,
  [NEW_PRODUCT_SUBSTEPS_IDS.PRODUCT_INFORMATION]: getProductInformationFields,
  [NEW_PRODUCT_SUBSTEPS_IDS.BOTTLED_BY]: getBottledByFields,
  [NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES]: getTastingNotesFields,
  [NEW_PRODUCT_SUBSTEPS_IDS.BARLEY]: getBarleyFields,
  [NEW_PRODUCT_SUBSTEPS_IDS.MASHING]: getMashingFields,
  [NEW_PRODUCT_SUBSTEPS_IDS.FERMENTATION]: getFermentationFields,
  [NEW_PRODUCT_SUBSTEPS_IDS.DISTILLATION]: getDistillationFields,
  [NEW_PRODUCT_SUBSTEPS_IDS.MATURATION]: getMaturationFields,
  [NEW_PRODUCT_SUBSTEPS_IDS.IMPORTER]: getImporterFields
};

export const NEW_PRODUCT_SUBSTEPS_AD_PROPS = {
  [NEW_PRODUCT_SUBSTEPS_IDS.SELECT_TEMPLATE]: {
    oneColumnForm: true
  },
  [NEW_PRODUCT_SUBSTEPS_IDS.PRODUCT_INFORMATION]: {
    oneColumnForm: true
  },
  [NEW_PRODUCT_SUBSTEPS_IDS.BOTTLED_BY]: {
    oneColumnForm: true
  },
  [NEW_PRODUCT_SUBSTEPS_IDS.TASTING_NOTES]: {
    oneColumnForm: true,
    formContainerClassName: styles.tastingNotesForm,
    noTitle: true
  },
  [NEW_PRODUCT_SUBSTEPS_IDS.BARLEY]: {
    oneColumnForm: true
  },
  [NEW_PRODUCT_SUBSTEPS_IDS.MASHING]: {
    oneColumnForm: true
  },
  [NEW_PRODUCT_SUBSTEPS_IDS.FERMENTATION]: {
    oneColumnForm: true
  },
  [NEW_PRODUCT_SUBSTEPS_IDS.DISTILLATION]: {
    oneColumnForm: true
  },
  [NEW_PRODUCT_SUBSTEPS_IDS.MATURATION]: {
    oneColumnForm: true
  },
  [NEW_PRODUCT_SUBSTEPS_IDS.IMPORTER]: {
    oneColumnForm: true
  }
};

export const NEW_PRODUCT_SUBSTEPS_INFO = {
  target: NEW_PRODUCT_TARGET,
  substeps: NEW_PRODUCT_SUBSTEPS,
  fields: NEW_PRODUCT_COMPLETE_FIELDS,
  additionalProps: NEW_PRODUCT_SUBSTEPS_AD_PROPS
};

export const SELECT_TEMPLATE_FIELDS = {
  TEMPLATE_TYPE: 'templateType'
};

export const PRODUCT_INFORMATION_FIELDS = {
  PRODUCT_NAME: 'productName',
  UNITS_TO_BE_CREATED: 'unitsToBeCreated',
  PRODUCT_DATE: 'productDate',
  PRODUCT_PICTURE: 'productPicture',
  TOTAL_CIRCULATING_BOTTLES: 'totalCirculatingBottles'
};

export const TASTING_NOTES_FIELDS = {
  TASTING_NOTES: 'tastingNotes',
  NOSE: 'nose',
  PALATE: 'palate',
  LANGUAGE: 'language'
};

export const BARLEY_FIELDS = {
  BARLEY_VARIETY: 'barleyVariety',
  FARM_NAME: 'farmName',
  FIELD_NAME: 'fieldName',
  MALTED_BY: 'maltedBy'
};

export const MASHING_FIELDS = {
  MASH_OPERATOR: 'mashOperator',
  FIRST_TEMPERATURE: 'firstTemperature',
  SECOND_TEMPERATURE: 'secondTemperature',
  THIRD_TEMPERATURE: 'thirdTemperature',
  GRAVITY: 'gravity'
};

export const FERMENTATION_FIELDS = {
  FERMENTATION_OPERATOR: 'fermentationOperator',
  AVERAGE_HOURS: 'averageHours',
  TYPE_OF_YEAST: 'typeOfYeast'
};

export const DISTILLATION_FIELDS = {
  STILL_OPERATOR: 'stillOperator',
  MIN_HYDROMETER: 'minHydrometer',
  MAX_HYDROMETER: 'maxHydrometer'
};

export const MATURATION_FIELDS = {
  CASK_TYPE: 'caskType',
  WOOD_FILL: 'woodFill',
  SPIRIT_TYPE: 'spiritType',
  VINTAGE: 'vintage',
  CASK_NO: 'caskNO',
  MATURATION_FILE: 'maturationFile'
};

export const IMPORTER_FIELDS = {
  IMPORTERS_NAME: 'importersName',
  WEBSITE: 'website',
  EMAIL_ADDRESS: 'emailAddress'
};
