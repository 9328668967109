import { t } from 'i18next';

import AdelphiFieldsRow from '#components/business/AdelphiFieldsRow';
import Checkbox from '#components/general/Checkbox';
import Input from '#components/general/Input';
import { MEASURE_TYPES } from '#components/general/Input/components/Measure/constants';
import { FROM_0_TO_100_W1_DECIMAL_REGEX } from '#utils/string/regex';

import styles from './styles.module.scss';

const isSum100Percentage = (fields, values) => () => {
  const fieldsSum = fields.reduce((prevValue, actValue) => {
    const currentValue = values[`${actValue}Manual`];
    const isActiveField = values[actValue];
    return prevValue + (isActiveField && currentValue ? parseInt(currentValue) : 0);
  }, 0);
  return fieldsSum === 100;
};

export const getCheckboxesPercentagesRows = (fieldsToBuild, getValues, values) =>
  fieldsToBuild.map(field => ({
    key: `${field}Row`,
    Component: AdelphiFieldsRow,
    fields: [
      {
        ...getValues(field),
        Component: Checkbox,
        noMargin: true,
        innerFieldContainerClassName: styles.checkboxColumn
      },
      ...(values[field]
        ? [
            {
              ...getValues(`${field}Manual`),
              Component: Input,
              placeholder: t('CheckboxPercentage:label'),
              measure: MEASURE_TYPES.PERCENTAGE,
              needsRegex: true,
              customRegex: FROM_0_TO_100_W1_DECIMAL_REGEX,
              registerParams: { required: true, min: 1, validate: isSum100Percentage(fieldsToBuild, values) }
            }
          ]
        : [
            {
              key: 'blankSpace',
              Component: () => <div />
            }
          ])
    ]
  }));
